import { useForm } from "react-hook-form";
import { Button,SelectSingleField } from "../../../composables";
import { BookingCard } from "../cards/BookingCard.component";
import { MdMenu } from "react-icons/md";
import { Loader2Icon } from "lucide-react";

export function BookingList({
  islist,
  setList,
  toggleSideBar,
  loading,
  setTripData,
  trip,
  data,
  handleChange,
}: {
  islist: boolean;
  loading: boolean;
  handleChange: (value: string) => void;
  setTripData: (item: any) => void;
  trip: any,
  data: any;
  setList: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSideBar: () => void;
}) {
  const { control } = useForm();

  const status = [
    { value: "pending", label: "Pending" },
    { value: "shipping", label: "Shipping" },
    { value: "delivered", label: "Delivered" },
    { value: "pickup", label: "Pickup" },
    { value: "accepted", label: "Accepted" },
  ];
  return (
    <div
      className={` max-[880px]:w-full min-[880px]:w-[320px] overflow-y-auto no-scrollbar h-full rounded-lg flex flex-col items-center bg-gray-50 px-2 py-2 sm:py-4 ${
        islist ? "max-[880px]:hidden" : "block"
      }`}
    >
      <div className="flex w-full items-center justify-start">
        <Button onClick={toggleSideBar} className="lg:hidden bg-transparent">
          <MdMenu className="text-gray-700 text-2xl" />
        </Button>
        <SelectSingleField
          name="status"
          placeHolder="Status"
          options={status}
          width="150px"
          control={control}
          onChanged={(v) => handleChange(v.value)}
        />
      </div>

      <div className="w-full flex flex-col mt-2 gap-y-2">
      {loading && (
        <div className="flex items-center justify-center w-full h-[250px]">
          <Loader2Icon size={48} className=" animate-spin text-gray-600" />
        </div>
      )}
      {!loading && data?.length === 0 && (
        <div className="flex items-center justify-center w-full h-[250px]">
          <p className="text-red-600">- No Data -</p>
        </div>
      )}
        {Array.isArray(data) &&
          data?.map((item) => (
            <div
              key={item._id}
              onClick={() => {
                setList(true);
               
                setTripData(item);
              }}
              className="w-full"
            >
              <BookingCard
                itemId={item?._id}
                activeId={trip?._id}
                sender={item.senderaddress}
                receiver={item.receiveraddress}
                status={item.order_status}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
