import authImage from "../../assets/png/authimage.jpg";
export function AuthLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="w-full flex gap-x-2 justify-center h-full inset-0 fixed items-center">
      <div className="hidden lg:block lg:w-[50%] h-full p-4">
       <div className="w-full h-full rounded-xl overflow-hidden relative">
       <img src={authImage} alt="" className="w-full h-full object-cover" />
       <div className="w-full h-full inset-0 absolute bg-black/20"></div>
       </div>
      </div>
      <div className="w-full lg:w-[50%]  min-h-screen ">
        <div className="flex flex-col items-center justify-center min-h-screen mx-auto w-full px-4 sm:w-[500px] ">
        {children}
        </div>
    
        </div>
     
    </div>
  );
}
