import { Container } from "../container/Container.component";
import { MetricCard, NotificationCard } from "./_components";
import users from "../../assets/svg/users.svg";
import products from "../../assets/svg/products.svg";
import orders from "../../assets/svg/orders.svg";
import sales from "../../assets/svg/sales.svg";
import user from "../../assets/png/user.png";
import { SalesChart } from "../composables";
import BarCharts from "../composables/barChart/BarChart.component";
import CircularChart from "../composables/circularChart/CircularChart.component";
import { Link } from "react-router-dom";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { dashboard } from "../../utils/api";
import { useSelector } from "react-redux";
export default function Home() {
  const { currentUser } = useSelector((state: any) => state.user);
  const { data } = useGetQueries(
    { adminid: currentUser.id || currentUser?._id },
    dashboard
  );


  return (
    <Container>
      <div className="w-full flex gap-y-4 sm:gap-y-8 flex-col items-start justify-start">
        <div className="w-full grid grid-col-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
          <MetricCard
            name="Total Riders"
            metric={data?.totalrider ?? 0}
            color="#04CE00"
            icon={<img src={users} alt="" />}
          />
          <MetricCard
            name="Total Users"
            metric={data?.totaluser ?? 0}
            color="#F0C05A"
            icon={<img src={products} alt="" />}
          />
          <MetricCard
            name="Total Business"
            metric={data?.bussiness ?? 0}
            color="#2D5BFF"
            icon={<img src={sales} alt="" />}
          />
          <MetricCard
            name="Total Orders"
            metric={data?.bussiness ?? 0}
            color="#2D5BFF"
            icon={<img src={orders} alt="" />}
          />
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-6 gap-4">
          <SalesChart />
          <CircularChart
            activeTrips={data?.activetrips ?? 0}
            onlineDrivers={data?.onlinedriver ?? 0}
            offlineDrivers={data?.offlinedriver ?? 0}
          />
        </div>
        <div className="w-full grid grid-cols-1 gap-4">
          <BarCharts />
          <div className="hidden w-full border p-4 md:col-span-2 rounded-lg">
            <div className="flex mb-6 items-center justify-between w-full">
              <p className="font-semibold text-sm sm:text-lg">
                My Notifications
              </p>
              <Link to="/chat">See All</Link>
            </div>

            {[user, user, user, user].map((image, idx) => (
              <NotificationCard image={image} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
}
