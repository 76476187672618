import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { errorToast } from "../utils/errorToast";
import { useToast } from "../utils/toastContainer";
import { AxiosResponse } from "axios";

export function useGetQueries(
  payload: any,
  queryFunction: (
    payload: any,
    token: string | null
  ) => Promise<AxiosResponse<any, any>>
) {
  const [data, setData] = useState<any | any[]>([] || null);
  const { token } = useSelector((state: any) => state.user);
  const { toast } = useToast();
  const [loading, setloading] = useState(true);

  async function fetchData() {
    if (!token) return;
    setData([] || null);
    setloading(true);
    await queryFunction(payload, token)
      .then((res: any) => {
        const { data } = res?.data;
        setloading(false);
        setData(data);
      })
      .catch((error) => {
        errorToast(error, toast);
        setloading(false);
      });
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    data,
    loading,
    refetch: fetchData,
  };
}
