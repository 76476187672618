import * as z from "zod"

export const loginValidationSchema = z.object({
    email: z
      .string()
      .email({ message: 'Email must be a valid email' })
      .min(1, { message: 'Email is required' }),
    password: z
      .string()
      .min(6, { message: 'Password must be at least 6 characters' })
      .min(1, { message: 'Password is required' }),
  });
  

  export const newsLetterSchema = z.object({
    subject: z.string().min(2, {message: "Subject is required"}),
    content: z.string().min(2, {message: "Content is required"}),
    emails: z.any()
  })

  export const smptSchema = z.object({
    host: z.string().min(1, { message: " Host is required" }),
    port: z.string().min(1, { message: " Port is required" }),
    username: z.string().min(1, { message: " UserName is required" }),
    password: z.string().min(4, { message: " Password must not be less than 4" }),
    mailfromaddress: z.string().min(1, { message: "Address is required" }),
    mailfromname: z.string().min(1, { message: " Name is required" }),
  });

  export const staffsSchema = z.object({
    email: z.string().email({ message: "Email must be a valid email" }),
    password: z
      .string()
      .min(6, { message: "Password must be at least 6 characters" })
      .min(1, { message: "Password is required" }),
    name: z.string().min(2, { message: "Name is required" }),
    phone: z
      .string()
      .refine((value) => value && /^(\+\d{11,}|\d{11,})$/.test(value), {
        message: "Phone number must be at least 11 digits",
      }),
      role: z.any()
  });
  