import Chart from "react-apexcharts";
import { formatNumber } from "../../../utils/formatNumber";
import { ApexOptions } from "apexcharts";

const BarCharts = () => {
  const options: ApexOptions = {
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: 300,
        columnWidth: "50%",
        borderRadius: 3,
      },
    },
    title: {
      text: "Sales Category",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
    },
    grid: {
      show: false,
    },
    xaxis: {
      labels: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    colors: ["#BDBDD1"],
    yaxis: {
      labels: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
      title: {
        text: "",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return formatNumber(val);
        },
      },
    },
  };

  const series = [
    {
      name: "Booking Activity",
      data: [20, 40, 50, 30, 60, 40, 50],
    },
  ];
  return (
    <div className="w-full  border h-fit bg-white rounded-lg p-3 sm:p-4">
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height="300"
      />
    </div>
  );
};

export default BarCharts;
