import { ReactElement } from "react";
// import { IoAnalytics } from "react-icons/io5";
// import MetricChart from "../../../composables/metricChart/MeticChart.component";

export function MetricCard({
  color,
  name,
  metric,
  icon,
}: {
  color: string;
  name: string;
  metric: string;
  icon: ReactElement;
}) {
  return (
    <div className="w-full flex bg-gradient-to-r from-dashx/50 via-purple-400 to-pink-500 border flex-col items-start rounded-md justify-start relative px-6 py-10 ">
      <div className="flex flex-col items-start justify-start">
        <p hidden>Statistics</p>
        <p className="text-[#221662] font-medium">{name}</p>
      </div>
      <div className="flex justify-start flex-col  w-full items-start ">
      
        <h2 className="font-semibold text-[#221662] text-xl sm:text-3xl">
            {metric}
          </h2>
        {/*<MetricChart color={color} />
          <div className="flex flex-col items-start gap-y-1 justify-start">
          
          <div style={{ color }} className="hidden items-center gap-x-2">
            <span>+23.45%</span> <IoAnalytics className="text-xl" />
          </div>
        </div>


        */}
      </div>
    </div>
  );
}
