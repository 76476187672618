import { useState } from "react";
import { ChatList, CurrentChat } from "./_components";
import { Container } from "../container/Container.component";

export default function Chats() {
  const [islist, setList] = useState(false);
  const [isSideBar, setSideBar] = useState(false);
  const [currentChatId, setCurrentChatId] = useState("");
  const [active, setActive] = useState("drivers");
  const [name, userName] = useState("");

  function toggleSideBar() {
    setSideBar((prev) => !prev);
  }

  function setId(id: string) {
    setCurrentChatId(id);
  }

  function setUserName(name: string) {
    userName(name);
  }

  return (
    <Container
      showTopNav={false}
      showSideMenu={isSideBar}
      className="flex min-[881px]:py-2 pt-2 pb-2 sm:pt-2 min-[881px]:pr-1 bg-gray-100 lg:pl-[254px] lg:w-full sm:px-1 gap-4 fixed  w-full h-full items-start"
    >
      <ChatList
        toggleSideBar={toggleSideBar}
        islist={islist}
        setList={setList}
        currentChatId={currentChatId}
        active={active}
        setUserName={setUserName}
        setActive={setActive}
        setId={setId}
      />
      <CurrentChat
        active={active}
        name={name}
        islist={islist}
        setList={setList}
        currentChatId={currentChatId}
      />
    </Container>
  );
}
