import { Container } from "../../container/Container.component";
import { Button, InputField } from "../../composables";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { smptSchema } from "../../../validations";
import { Loader2Icon } from "lucide-react";
import { useEffect, useState } from "react";
import { useToast } from "../../../utils/toastContainer";
import { errorToast } from "../../../utils/errorToast";
import { retrieveSmtp, updateSmtp, createSmtp } from "../../../utils/api";
import { useSelector } from "react-redux";
import { MdCheck } from "react-icons/md";
import { useGetQueries } from "../../../hooks";
export default function SmtpSettings() {
  const [loading, setLoading] = useState(false);
  const { currentUser, token } = useSelector((state: any) => state.user);
  const { data } = useGetQueries(
    { adminid: currentUser?._id || currentUser?.id },
    retrieveSmtp
  );
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof smptSchema>>({
    resolver: zodResolver(smptSchema),
  });

  async function onSubmit(values: z.infer<typeof smptSchema>) {
    setLoading(true);
    const asynCQuery = data?._id ? updateSmtp : createSmtp;
    await asynCQuery(
      { ...values, adminid: currentUser?._id || currentUser?.id },
      token
    )
      .then((res) => {
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>SMTP updated Successfully</p>
            </div>
          ),
          className: "bg-green-100",
        });
      })
      .catch((err) => {
        errorToast(err, toast);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (data) {
      reset({
        host: data?.host,
        mailfromaddress: data?.mailfromaddress,
        port: data?.port,
        username: data?.username,
        password: data?.password,
        mailfromname: data?.mailfromname,
      });
    }
  }, [data, reset]);
  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full border rounded-md pb-4 flex mx-auto flex-col max-w-3xl gap-y-3 items-start justify-start"
      >
        <h3 className="w-full mb-3 text-start border-b p-4 font-semibold ">
          SMTP Settings
        </h3>

        <div className="w-full px-4">
          <InputField
            label="Mail Host"
            {...register("host")}
            type="text"
            hasLabel
            placeholder="Mail Host"
            error={errors?.host?.message}
          />
        </div>
        <div className="w-full px-4">
          <InputField
            label="Mail Port"
            {...register("port")}
            type="number"
            hasLabel
            placeholder="3423"
            error={errors?.port?.message}
          />
        </div>
        <div className="w-full px-4">
          <InputField
            label="Mail Username"
            {...register("username")}
            type="text"
            hasLabel
            placeholder="Mail Username"
            error={errors?.username?.message}
          />
        </div>
        <div className="w-full px-4">
          <InputField
            label="Mail Password"
            {...register("password")}
            type="password"
            hasLabel
            placeholder="Mail Password"
            error={errors?.password?.message}
          />
        </div>
        <div className="w-full px-4">
          <InputField
            label="Mail From Address"
            {...register("mailfromaddress")}
            type="text"
            hasLabel
            placeholder="Mail From Address"
            error={errors?.mailfromaddress?.message}
          />
        </div>
        <div className="w-full px-4">
          <InputField
            label="Mail From Name"
            {...register("mailfromname")}
            type="text"
            hasLabel
            placeholder="Mail From Name"
            error={errors?.mailfromname?.message}
          />
        </div>

        <Button
          disabled={loading}
          className="w-[140px] mx-4 gap-x-2 self-end text-gray-50 font-medium bg-dashx"
        >
          {loading && <Loader2Icon className="animate-spin" />}
          <p> Save</p>
        </Button>
      </form>
    </Container>
  );
}
