import { useNavigate } from "react-router-dom";
import { Button } from "../composables";
import { Container } from "../container/Container.component";
import { StaffCard } from "./_components/StaffCard.component";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { retrieveStaffs } from "../../utils/api";
import { useSelector } from "react-redux";
import { checkPermission } from "../../hooks";
export default function Staffs() {
  const navigate = useNavigate();
  const {currentUser} = useSelector((state:any) => state.user)
const {data, refetch} = useGetQueries({adminid: currentUser?._id || currentUser?.id},retrieveStaffs)
 const status = checkPermission("CreateStaffs")


  return (
    <Container>
      <div className="flex mb-4 sm:mb-6 items-center justify-between w-full">
        <h2 className="font-semibold text-base sm:text-lg">All Staffs</h2>
      {status &&  <Button
          onClick={() => navigate("/staffs/create")}
          className="text-gray-50 font-medium bg-dashx rounded-lg"
        >
          Create Staff
        </Button>}
      </div>

      <div className="border  rounded-md w-full ">
        <div className="py-4 flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between px-2 sm:px-4 w-full border-b">
          <h2 className=" font-semibold">Staffs</h2>
        </div>
        <div className="w-full font-semibold grid grid-cols-6 p-2 gap-2">
          <p>#</p>
          <p className="col-span-2">Name</p>
          <p>Role</p>
          <p className="col-span-2 text-end w-full">Options</p>
        </div>

        {Array.isArray(data) && data.map((item, index) => (
          <StaffCard id={index} key={item} item={item} refetch={refetch} />
        ))}
      </div>
    </Container>
  );
}
