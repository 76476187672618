import Select from "react-select";
import {
  UseControllerProps,
  UseControllerReturn,
  useController,
  FieldValues,
} from "react-hook-form";
import { cn } from "../../../lib";

function ErrorText({ children }: { children?: string }) {
  return (
    <div>
      {children && <p className="pt-1 text-xs text-red-500 ">{children}</p>}
    </div>
  );
}

export const SelectSingleField = <T extends FieldValues>(
  prop: UseControllerProps<T> & {
    options: { value: string; label: string }[];
    error?: string;
    label?: string;
    placeHolder: string;
    onChanged: (selectedValue: any) => void;
    hasLabel?: boolean;
    width?: string;
    preselectedValue?:{value:string, label:string}[]
  }
) => {
  const {
    label,
    options,
    error,
    placeHolder,
    width,
    hasLabel,
    onChanged,
    preselectedValue,
    ...controllerProps
  } = prop;
  const {
    field: { onChange },
  } = useController(controllerProps) as UseControllerReturn<T>;

  return (
    <div style={{ width }} className="flex flex-col items-start justify-start">
      {hasLabel && (
        <label htmlFor="select" className={cn("mb-3", error && "text-red-500")}>
          {label}
        </label>
      )}
      <Select
      
        placeholder={placeHolder}
        defaultValue={preselectedValue}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state?.isFocused ? "#d1d5db" : "#d1d5db",
            height: "44px",
            width,
            backgroundColor: "#ffffff",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            textAlign: "start",
            color: state?.isSelected ? "black" : "black",
            backgroundColor: state?.isFocused ? "#d1d5db" : "",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            textAlign: "start",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            textAlign: "start",
          }),
        }}
        options={options}
        onChange={(newValue) => {
          onChange(newValue)
          // if (onChanged) {
            onChanged(newValue)

        //  }
        }}
      />
      <ErrorText>{error}</ErrorText>
    </div>
  );
};
