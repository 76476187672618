// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALzwT6PGdsKTrhMDbphAhtP6jZBPfgRfM",
  authDomain: "smartbest-c57a2.firebaseapp.com",
  projectId: "smartbest-c57a2",
  storageBucket: "smartbest-c57a2.appspot.com",
  messagingSenderId: "410097732080",
  appId: "1:410097732080:web:fb306343c67d5621d23535",
  measurementId: "G-RZ7S7BGDNW"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
