import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Button } from "../../../composables";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { deleteRole } from "../../../../utils/api";
import { DeleteCard } from "../../../composables/delete/Delete.component";
// import { deleteRole } from "../../../../utils/api";
import { checkPermission } from "../../../../hooks";

export function RoleCard({
  id,
  item,
  refetch,
}: {
  refetch: () => Promise<any>;
  id: number;
  item: any;
}) {
  const [isShow, setShow] = useState(false);
  const navigate = useNavigate();
  const status = checkPermission("CreateRoles");
  const deleteStatus = checkPermission("DeleteRoles");

  function onClose() {
    setShow((prev) => !prev);
  }

  return (
    <>
      <div className="w-full grid items-center grid-cols-5 gap-2 border-b p-2">
        <div className="flex items-center gap-1">
          <p>{id + 1}</p>
        </div>

        <p className="capitalize col-span-2">{item?.role ?? ""}</p>

        <div className="flex items-center w-full  justify-end col-span-2 gap-x-2">
          {status && (
            <Button
              onClick={() => {
                navigate("/staffs/role/create", {
                  state: {
                    role: item,
                  },
                });
              }}
              className="bg-yellow-200 h-9 w-9 px-1 rounded-full"
            >
              <FiEdit className="text-yellow-500 text-base sm:text-lg" />
            </Button>
          )}
          {deleteStatus && (
            <Button
              onClick={onClose}
              title="Delete"
              className="bg-red-200 h-9 w-9 px-1 rounded-full"
            >
              <MdDelete className="text-red-500 text-base sm:text-lg" />
            </Button>
          )}
        </div>
      </div>
      {isShow && (
        <DeleteCard
          deleteFunction={deleteRole}
          close={onClose}
          refetch={refetch}
          deletePayload={{ roleid: item?._id }}
        />
      )}
    </>
  );
}
