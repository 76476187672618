import { FiAlertTriangle } from "react-icons/fi";
import { Button } from "../button/Button.component";
import { useSelector } from "react-redux";
import { useToast } from "../../../utils/toastContainer";
import { errorToast } from "../../../utils/errorToast";
import { MdCheck } from "react-icons/md";
import { useState } from "react";
import { AxiosResponse } from "axios";
import { Loader2Icon } from "lucide-react";
export function DeleteCard({
  deleteFunction,
  deletePayload,
  refetch,
  close,
}: {
  deleteFunction: (
    p: any,
    t: string | null
  ) => Promise<AxiosResponse<any, any>>;
  deletePayload: {};
  refetch: () => Promise<any>;
  close: () => void;
}) {
  const { currentUser, token } = useSelector((state: any) => state.user);
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  async function deletes() {
    setLoading(true);
    await deleteFunction(
      { ...deletePayload, adminid: currentUser?._id || currentUser?.id },
      token
    )
      .then((res) => {
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>Item deleted Successfully</p>
            </div>
          ),
          className: "bg-green-100",
        });
      })
      .catch((err) => {
        errorToast(err, toast);
      })
      .finally(() => {
        setLoading(false);
        refetch();
        close();
      });
  }
  return (
    <div
      onClick={close}
      className="w-full h-full inset-0 z-[500] fixed backdrop-blur"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-[95%] sm:w-[500px] rounded-md bg-white py-6  shadow-md m-auto absolute inset-0 h-fit"
      >
        <div className="flex w-full flex-col items-center justify-center">
          <FiAlertTriangle className="text-5xl" />
          <p className="font-medium">Are you sure you want to delete?</p>
        </div>

        <div className="flex w-full items-end justify-end gap-x-6 px-4 pt-2 border-t">
          <Button
            onClick={close}
            className="bg-gray-200 font-medium rounded-md"
          >
            Cancel
          </Button>
          <Button
            onClick={deletes}
            className="bg-dashx gap-x-2 text-white font-medium rounded-md"
          >
            {loading && <Loader2Icon />}
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
}
