import { useForm } from "react-hook-form";
import * as z from "zod";
import { loginValidationSchema } from "../../validations";
import { zodResolver } from "@hookform/resolvers/zod";
import { InputField, Button, PasswordField } from "../composables";
import { Link, useNavigate } from "react-router-dom";
import { logIn } from "../../utils/api";
import { useDispatch } from "react-redux";
import {
  GetUsersSuccess,
  LoginSuccess,
} from "../../Redux/actions/ActionCreator";
import { errorToast } from "../../utils/errorToast";
import { Loader2Icon } from "lucide-react";
import { useToast } from "../../utils/toastContainer";
import { MdCheck } from "react-icons/md";
import { AuthLayout } from "../authLayout/AuthLayout.component";
import { useReformedNavLink } from "../../context/AdminPermission";
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const {setReformedNavLink} = useReformedNavLink()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<z.infer<typeof loginValidationSchema>>({
    resolver: zodResolver(loginValidationSchema),
  });

  async function onSubmit(value: z.infer<typeof loginValidationSchema>) {

    await logIn(value)
      .then((res) => {
        const { data } = res?.data;
        dispatch(GetUsersSuccess(data));
        dispatch(LoginSuccess(data?.token));
        setReformedNavLink([]);
        navigate("/verifying");
        
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>Log In Successful</p>
            </div>
          ),
          className: "bg-green-200",
        });
      })
      .catch((error) => {
        errorToast(error, toast);
      });
  }

  return (
    <AuthLayout>
      <h2 className="mb-10 sm:mb-20 text-lg text-center w-full sm:text-2xl font-semibold ">
        Welcome Back! We’ve missed you.
      </h2>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-start justify-start  gap-y-3"
      >
        <InputField
          placeholder="ola@example.com"
          
          {...register("email")}
          type="email"
          error={errors.email?.message}
        />

        <PasswordField
          placeholder="*****"
          
          {...register("password")}
          error={errors.password?.message}
        />

        <div className="w-full flex items-end justify-end">
          <Link
            className="text-dashx font-semibold"
            to="/forgot-password"
          >forgot password?</Link>
        </div>

        <Button
          disabled={isSubmitting}
          className="w-full bg-dashx mt-6 hover:bg-opacity-70 gap-x-2 text-white shadow-lg"
        >
          {isSubmitting && <Loader2Icon className="animate-spin text-[22px]" />}
          <span> Log In</span>
        </Button>
      </form>
    </AuthLayout>
  );
}
