import { Button, InputField } from "../../../composables";
import profile from "../../../../assets/png/profile.png";
import { BiSolidEdit } from "react-icons/bi";
import { Loader2Icon } from "lucide-react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateAdmin, userProfile } from "../../../../utils/api";
import { useToast } from "../../../../utils/toastContainer";
import { errorToast } from "../../../../utils/errorToast";
import { MdCheck } from "react-icons/md";
import { useEffect } from "react";
import { GetUsersSuccess } from "../../../../Redux/actions/ActionCreator";
import { useGetQueries } from "../../../../hooks/getQueries.hook";

type FormValue = {
  adminid: string;
  phone: string;
  name: string;
  email: string;
};
export function Profile() {
  const dispatch = useDispatch();
  const { currentUser, token } = useSelector((state: any) => state.user);
  const {  loading, data } = useGetQueries(
    { adminid: currentUser?.id || currentUser?._id },
    userProfile
  );
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValue>({
    defaultValues: {
      adminid: currentUser?.id || currentUser?._id,
      phone: currentUser?.phone,
      name: currentUser?.name,
      email: currentUser?.email,
    },
  });
  useEffect(() => {
    if (!loading) {
      if (data?.length === 0 || data === null) return;
      dispatch(GetUsersSuccess(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  async function onSubmit(values: FormValue) {
    await updateAdmin(values, token)
      .then((res) => {
        // const { data } = res?.data;

        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>User Updated</p>
            </div>
          ),
          className: "bg-green-200",
        });
      })
      .catch((error) => {
        errorToast(error, toast);
      });
  }
  return (
    <div>
      <div className="w-full border-b py-2 space-y-1 flex items-start flex-col  justify-start">
        <h2 className="font-semibold text-base sm:text-xl">Profile</h2>
        <p>Get notified with what’s happening around you</p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex py-4 flex-col gap-y-3 sm:gap-y-4 items-center justify-center "
      >
        <div className="hidden w-[150px] h-[150px] rounded-full relative">
          <img src={profile} alt="" className="w-full h-full rounded-full" />

          <label
            id="upload"
            className="rounded-full absolute right-[-2px] top-4 text-white bg-dashx w-9 h-9 flex items-center justify-center"
          >
            <BiSolidEdit className=" text-xl" />
            <input hidden type="file" id="upload" className="w-full h-full" />
          </label>
        </div>

        <div className="w-full border-b py-2 space-y-1 flex items-start justify-start">
          <h2 className="font-semibold text-base sm:text-xl">
            Personal Details
          </h2>
        </div>

        <div className="w-full grid grid-cols-1 gap-4 sm:grid-cols-2 ">
          <InputField
            className="bg-[#F9F9F9]"
            placeholder="Fullname"
            type="text"
            {...register("name")}
            hasLabel
            label="Full Name"
          />

          <InputField
            className="bg-[#F9F9F9]"
            placeholder="+234 8043032023"
            type="tel"
            {...register("phone")}
            hasLabel
            label="Phone Number"
          />
        </div>

        <div className="w-full flex mt-6 justify-end items-end">
          <Button
            disabled={isSubmitting}
            className="w-fit bg-dashx  hover:bg-opacity-70 gap-x-2 text-white shadow-lg"
          >
            {isSubmitting && (
              <Loader2Icon className="animate-spin text-[22px]" />
            )}
            <span>Save Changes</span>
          </Button>
        </div>
      </form>
    </div>
  );
}
