import { SettingLayout } from "./_components";
import { SettingContextProvider } from "./setingContext/SettingContext.context";

export default function Setting() {
  return (
    <SettingContextProvider>
      <SettingLayout />
    </SettingContextProvider>
  );
}
