import { MdNavigateBefore } from "react-icons/md";
import { Button } from "../../../composables";
import { BookingDetail } from "../cards/BookingDetail.component";
import {
  APIProvider,
  Map,
  useMapsLibrary,
  useMap,
  Marker
} from "@vis.gl/react-google-maps";
import axios from "axios"
import { useMemo, useEffect, useState } from "react";

interface AddressResponse {
  status: string;
  results: { formatted_address: string }[];
}

export function GoogleMap({
  islist,
  setList,
  trip,
}: {
  islist: boolean;
  trip: any;
  setList: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [addresses, setAddresses] = useState<(string | null)[]>([]);
  const receiverPosition = useMemo(() => {
    return {
      lat: Number(trip?.receivercordinate?.receiverlat ?? 0.0),
      lng: Number(trip?.receivercordinate?.receiverlong ?? 0.0),
    };
  }, [
    trip?.receivercordinate?.receiverlat,
    trip?.receivercordinate?.receiverlong,
  ]);

  useEffect(() => {
    (async () => {
      if (trip) {
        const coordinates = [
          {
            latitude: trip?.dispatchid?.cordinate?.latitude,
            longitude: trip?.dispatchid?.cordinate?.longitude,
          },
          {
            latitude: trip?.receivercordinate?.receiverlat,
            longitude: trip?.receivercordinate?.receiverlong,
          },
        ];

        const promises = coordinates.map(async ({ latitude, longitude }) => {
          return axios
            .get<AddressResponse>(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCgcr6Ebgd6okFz0LE0Jj74kz1oXMnHlTM`
            )
            .then((response) => {
              if (response.data.status === "OK") {
                return response.data.results[0]?.formatted_address || null;
              } else {
                console.error(
                  "Failed to retrieve address:",
                  response.data.status
                );
                return null;
              }
            })
            .catch((error) => {
              console.error("Error occurred while fetching address:", error);
              return null;
            });
        });

        const addresses = await Promise.all(promises);
        setAddresses(addresses);
        
      }
    })();
  }, [trip]);

  return (
    <div
      className={` relative max-[880px]:w-full min-[880px]:w-[70%] rounded-lg h-full overflow-hidden ${
        islist ? "block" : "max-[880px]:hidden"
      }`}
    >
      <Button
        onClick={() => {
          setList((prev) => !prev);
        }}
        className="bg-dashx/40 z-[300] px-2 h-11 w-11 absolute top-3 left-3 rounded-full"
      >
        <MdNavigateBefore className="text-[22px]" />
      </Button>
      <APIProvider apiKey={"AIzaSyCgcr6Ebgd6okFz0LE0Jj74kz1oXMnHlTM"}>
        <Map
            key={`${addresses[0] ?? ""}_${addresses[1] ?? ""}`}
          fullscreenControl={false}
          center={receiverPosition}
          zoom={10}
        >
                    {/* Marker for starting point */}
                    {addresses?.[0] && (
            <Marker
              key="start"
              position={{
                lat: parseFloat(trip?.dispatchid?.cordinate?.latitude),
                lng: parseFloat(trip?.dispatchid?.cordinate?.longitude),
              }}
              label="" // Custom label for the marker
              icon={{
                url: "https://firebasestorage.googleapis.com/v0/b/preem-whatsapp-cloning-cd897.appspot.com/o/images%2Fellipse-removebg-preview.png?alt=media&token=56a1f7f9-8dc1-4f18-b495-9213eb6dfcc8", // Custom marker icon
                scaledSize: new window.google.maps.Size(24, 24), // Adjust size if needed
                anchor: new window.google.maps.Point(12, 24)
              }}

            />
          )}
          
          {/* Marker for destination */}
          {addresses?.[1] && (
            <Marker
              key="end"
              position={{
                lat: parseFloat(trip?.receivercordinate?.receiverlat),
                lng: parseFloat(trip?.receivercordinate?.receiverlong),
              }}
              label="" // Custom label for the marker
              icon={{
                url: " https://firebasestorage.googleapis.com/v0/b/preem-whatsapp-cloning-cd897.appspot.com/o/images%2Fcircle-removebg-preview.png?alt=media&token=9c427e4a-4c51-406c-b451-c1649ba5d716", // Custom marker icon
                scaledSize: new window.google.maps.Size(24, 24), // Adjust size if needed
              }}
            />
          )}
       {addresses?.length > 0 && (
            <Directions
              senderAddress={addresses[0] ? addresses[0] : ""}
              receiverAddress={addresses[1] ? addresses[1] : ""}
            />
          )}
        </Map>
      </APIProvider>

      <div className="absolute bottom-2 inset-x-0 mx-auto w-full h-fit">
        <BookingDetail trip={trip} />
      </div>
    </div>
  );
}

export function Directions({
  senderAddress,
  receiverAddress,
}: {
  senderAddress: string;
  receiverAddress: string;
}) {
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] =
    useState<google.maps.DirectionsService>();
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer>();
  const [routes, setRoutes] = useState<google.maps.DirectionsRoute[]>([]);
  const [routeIndex, setRouteIndex] = useState(0);
  const selected = routes[routeIndex];
  const leg = selected?.legs[0];

  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(
      new routesLibrary.DirectionsRenderer({
        map,
        suppressMarkers: true,
        suppressPolylines: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesLibrary, map]);

  // Use directions service
  useEffect(() => {
    if (!directionsService || !directionsRenderer) return;

    directionsService
      .route({
        origin: senderAddress,
        destination: receiverAddress,
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
        setRoutes(response.routes);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => directionsRenderer.setMap(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderAddress, receiverAddress, directionsService, directionsRenderer]);

  // Update direction route
  useEffect(() => {
    if (!directionsRenderer) return;
    directionsRenderer.setRouteIndex(routeIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeIndex, directionsRenderer]);

  if (!leg) return null;

  return (
    <div className="directions">
      <h2>{selected.summary}</h2>
      <p>
        {leg.start_address.split(",")[0]} to {leg.end_address.split(",")[0]}
      </p>
      <p>Distance: {leg.distance?.text}</p>
      <p>Duration: {leg.duration?.text}</p>

      <h2>Other Routes</h2>
      <ul>
        {routes.map((route, index) => (
          <li key={route.summary}>
            <button onClick={() => setRouteIndex(index)}>
              {route.summary}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
