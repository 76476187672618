import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

export function SalesChart() {
  const options: ApexOptions = {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Overall Statistics",
      align: "left",
    },
    markers: {
      size: 0,
    },

    grid: {
      show: false,
    },

    xaxis: {
      labels: {
        show: true,
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
    yaxis: {
      show: true,
    },

    colors: ["#017297","#eab308"],
  };

  const series = [
    {
      show: true,
      name: "Drivers",
      data: [22, 34, 25, 24, 22, 30, 22, 26, 28],
    },
    {
      show: true,
      name: "Users",
      data: [11, 32, 45, 32, 34, 52, 41, 34, 46],
    },
  ];
  return (
    <div className="w-full md:col-span-4 border h-fit bg-white rounded-lg p-3 sm:p-4">
      <Chart
        options={options}
        series={series}
        type="area"
        width="100%"
        height="350"
      />
    </div>
  );
}
