import { GoEyeClosed, GoEye } from "react-icons/go";
type TogglePasswordVisibiltyProp = {
  isVisible: boolean;
  toggleVisibility: () => void;
};
export const TogglePasswordVisibilty = ({
  isVisible,
  toggleVisibility,
}: TogglePasswordVisibiltyProp) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        toggleVisibility();
      }}
      className="absolute top-3 right-3"
    >
      {isVisible ? (
        <GoEyeClosed className="text-xl" />
      ) : (
        <GoEye className="text-xl" />
      )}
    </button>
  );
};
