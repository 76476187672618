import { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { useLocation, Link } from "react-router-dom";
import { LiaUserCircleSolid } from "react-icons/lia";
import { CgMenuLeft } from "react-icons/cg";
import { cn } from "../../lib";
import { Button } from "../composables";
import { MdNavigateNext } from "react-icons/md";
import smart from "../../assets/png/logo.png";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { sysBalance } from "../../utils/api";
import { AiOutlineLogout } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useGetUser, useNavLinks } from "../../hooks";
export function Container({
  children,
  className,
  showTopNav = true,
  showSideMenu = false,
}: {
  children: React.ReactNode;
  className?: string;
  showTopNav?: boolean;
  showSideMenu?: boolean;
}) {
  const [isNav, setNav] = useState(showSideMenu);

  // validate user
  useGetUser();

  useEffect(() => {
    if (showSideMenu) {
      setNav(showSideMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSideMenu]);

  function onClose() {
    setNav((nav) => !nav);
  }

  return (
    <>
      {showTopNav && (
        <div
          className={`w-full lg:w-[calc(100%-250px)] min-[1024px]:float-right right-0 z-50 fixed bg-white border-b border-gray-200 px-3 py-3 sm:py-4 sm:px-6 flex justify-between items-center `}
        >
          <div className="justify-between  w-full flex items-center">
            <div className="flex items-center gap-x-1">
              <Button
                type="button"
                onClick={onClose}
                className=" border rounded-md "
              >
                <CgMenuLeft className="text-xl sm:text-2xl text-zinc-500" />
              </Button>
            </div>

            <div className="flex items-center gap-x-2">
              <Link to="/chat" className="px-2">
                <FaBell className="text-[22px] text-black" />
              </Link>

              <div className="hidden sm:flex items-center gap-x-1">
                <LiaUserCircleSolid className="text-3xl" />
                <button>
                  <MdNavigateNext className="text-xl rotate-90" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={cn(
          "lg:w-[calc(100%-250px)]  min-[1024px]:float-right px-2 sm:px-6 pt-[5rem] pb-12 sm:pt-24 ",
          className
        )}
      >
        {children}
      </div>
      <SideNavs isNav={isNav} close={onClose} />
    </>
  );
}

function SideNavs({ close, isNav }: { close: () => void; isNav: boolean }) {
  const { pathname } = useLocation();
  const { currentUser } = useSelector((state: any) => state.user);
  const [settingTab, showSettingTab] = useState(false);
  const [staffTab, showStaffTab] = useState(false);
  const dispatch = useDispatch();
  const { reformedNavLink } = useNavLinks();

  const { data: balance } = useGetQueries(
    { adminid: currentUser.id || currentUser?._id },
    sysBalance
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        close();
      }}
      className={`fixed z-[70] inset-y-0 left-0 h-full modal swipeInLeft ${
        isNav
          ? "w-full bg-white/50  min-[1024px]:w-[250px]"
          : "max-[1024px]:hidden w-[250px] "
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" py-3 bg-dashx text-gray-50 sm:py-4 flex flex-col relative overflow-y-auto items-center h-full w-[250px] cursor-pointer shadow-md"
      >
        <div className="flex  flex-col gap-y-5 items-center w-full justify-center">
          <div className="w-[100px] h-[60px]">
            <img src={smart} alt="" className="w-full h-full object-contain" />
          </div>

          <ul className="flex flex-col gap-y-0 items-start px-4 justify-start w-full">
            {balance?.data?.length > 0 && (
              <li className="px-3 py-2 mb-3 w-full flex flex-col items-start bg-white rounded-md text-dashx justify-start">
                <p className="font-medium text-xs sm:text-sm">
                  Current Balance
                </p>
                <p className="font-semibold text-base sm:text-lg">{`₦${(
                  balance?.data[0]?.balance ?? 0
                ).toLocaleString()}`}</p>
              </li>
            )}
            {reformedNavLink &&
              reformedNavLink.map(({ link, name, icon, tab }) => {
                if (name === "Settings") {
                  return (
                    <li key={name} className="relative w-full">
                      <button
                        onClick={() => {
                          showSettingTab((more) => !more);
                        }}
                        className="w-full p-3 flex items-center  justify-between pr-8"
                      >
                        <div className={cn("flex items-center gap-x-2 w-full")}>
                          {icon}
                          <span>{name}</span>
                        </div>
                        <MdNavigateNext
                          className={cn(
                            "text-[22px] rotate-90",
                            settingTab && "-rotate-90"
                          )}
                        />
                      </button>

                      {settingTab && (
                        <div className="w-full flex flex-col gap-y-1 justify-start items-start ">
                          {tab?.map((item) => (
                            <Link
                              onClick={() => showSettingTab(true)}
                              key={item?.name}
                              className={cn(
                                "  items-center gap-x-2 w-full pl-8 flex  py-3 pr-3",
                                pathname === String(item?.link) &&
                                  "text-dashx bg-gray-50 rounded-3xl shadow-xl"
                              )}
                              to={item?.link}
                            >
                              {item?.icon}
                              <p> {item?.name}</p>
                            </Link>
                          ))}
                        </div>
                      )}
                    </li>
                  );
                }
                if (name === "Staffs") {
                  return (
                    <li key={name} className="relative w-full">
                      <button
                        onClick={() => {
                          showStaffTab((more) => !more);
                        }}
                        className="w-full p-3 flex items-center  justify-between pr-8"
                      >
                        <div className={cn("flex items-center gap-x-2 w-full")}>
                          {icon}
                          <span>{name}</span>
                        </div>
                        <MdNavigateNext
                          className={cn(
                            "text-[22px] rotate-90",
                            staffTab && "-rotate-90"
                          )}
                        />
                      </button>

                      {staffTab && (
                        <div className="w-full flex flex-col gap-y-1 justify-start items-start ">
                          {tab?.map((item) => (
                            <Link
                              onClick={() => showStaffTab(true)}
                              key={item?.name}
                              className={cn(
                                "  items-center gap-x-2 w-full pl-8 flex  py-3 pr-3",
                                pathname === String(item?.link) &&
                                  "text-dashx bg-gray-50 rounded-3xl shadow-xl"
                              )}
                              to={item?.link}
                            >
                              {item?.icon}
                              <p> {item?.name}</p>
                            </Link>
                          ))}
                        </div>
                      )}
                    </li>
                  );
                }
                return (
                  <li key={name} className={"w-full"}>
                    <Link
                      to={link}
                      className={cn(
                        "p-3  flex  items-center gap-x-2 w-full",
                        link === pathname &&
                          "text-dashx bg-gray-50 rounded-3xl shadow-xl"
                      )}
                    >
                      {icon}
                      <span>{name}</span>
                    </Link>
                  </li>
                );
              })}

            <Button
              onClick={() => dispatch({ type: "LOGOUT" })}
              className="flex items-center justify-start gap-x-2 text-white mt-8"
            >
              <AiOutlineLogout className="text-[22px]" />
              <p>Log Out</p>
            </Button>
          </ul>
        </div>
      </div>
    </div>
  );
}
