import { useState } from "react";
import { CiSquarePlus } from "react-icons/ci";
import { FaSquareMinus } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { Button } from "../../composables";
import { cn } from "../../../lib";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { deleteStaffs } from "../../../utils/api";
 import { checkPermission } from "../../../hooks";
 import { DeleteCard } from "../../composables/delete/Delete.component";

export function StaffCard({
  id,
  item,
  refetch,
}: {
  id: number;
  refetch: () => Promise<any>;
  item: any;
}) {
  const [isShow, setShow] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false)
 const status = checkPermission("CreateStaffs")
  const deleteStatus = checkPermission("DeleteStaffs")

  function onClose() {
    setOpen((prev) =>!prev);
  
  }

  return (
    <>
      <div className="w-full grid items-center grid-cols-6 gap-2 border-b p-2">
        <div className="flex items-center gap-1">
          {!isShow ? (
            <button
              onClick={() => {
                setShow(!isShow);
              }}
            >
              <CiSquarePlus className="text-purple-600 text-xl" />
            </button>
          ) : (
            <button
              onClick={() => {
                setShow(!isShow);
              }}
            >
              <FaSquareMinus className="text-purple-600 text-xl" />
            </button>
          )}
          <p>{id + 1}</p>
        </div>
        <p className="col-span-2 capitalize">{item?.name ?? ""}</p>
       {Array.isArray(item?.roles) ? <p className="capitalize">{item?.roles[0]?.role ?? ""}</p>: <p className="w-1 h-1"></p>}

        <div className="flex items-center w-full  justify-end col-span-2 gap-x-2">
         {status && <Button
            onClick={() => {
             navigate("/staffs/create", {
              state: {
                staff: item
              }
             })
            }}
            className="bg-yellow-200 h-8 w-8 px-1 rounded-full"
          >
            <FiEdit className="text-yellow-500 text-xl" />
          </Button>}
         {deleteStatus && <Button
          onClick={onClose}
          title="Delete"
          className="bg-red-200 h-8 w-8 px-1 rounded-full">
            <MdDelete className="text-red-500 text-xl" />
          </Button>}
        </div>
      </div>
      <div
        className={cn(
          "items-start flex-col gap-y-2  mt-2  justify-start col-span-full p-2 border-b hidden",
          isShow && "flex flex-col"
        )}
      >
        <div className="flex items-start gap-x-6 w-full border-b py-4">
          <p className="font-medium">Email</p>
          <p>{item?.email ?? ""}</p>
        </div>
        <div className="flex items-start gap-x-6 w-full border-b py-4">
          <p className="font-medium">Phone</p>
          <p>{item?.phone ?? ""}</p>
        </div>
      </div>
      {isOpen && (
        <DeleteCard
          deleteFunction={deleteStaffs}
          close={onClose}
          refetch={refetch}
          deletePayload={{ staffid: item?._id }}
        />
      )}
    </>
  );
}
