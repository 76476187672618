import axios from "./useAxios";

export function logIn(payload: { email: string; password: string }) {
  return axios.post(`/admin/login`, payload);
}

export function forgotPassword(payload: any) {
  return axios.post(`/admin/forgot/password`, payload);
}
///
export function resetPassword(payload: any) {
  return axios.post(`/admin/reset/password`, payload);
}

export function createRole(payload: any, token: string | null) {
  return axios.post(`/admin/create/role`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function updateRole(payload: any, token: string | null) {
  return axios.post(`/admin/update/role`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function retrieveRole(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/role`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
//
export function deleteRole(payload: any, token?: string | null) {
  return axios.post(`/admin/delete/role`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
//
export function createStaffs(payload: any, token?: string | null) {
  return axios.post(`/admin/create/subadmin`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function updatStaffs(payload: any, token?: string | null) {
  return axios.post(`/admin/update/staff`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /admin/delete/staff
export function deleteStaffs(payload: any, token: string | null) {
  return axios.post(`/admin/delete/staff`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function retrieveStaffs(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/admins`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function userProfile(payload: any, token?: string | null) {
  return axios.post(`/admin/profile`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function retreivePricing(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/price`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function updatePricing(payload: any, token?: string | null) {
  return axios.post(`/admin/update/price`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//

export function retrieveUsers(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/all/user`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//

export function retrieveSingleUser(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/single/user`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//

export function retrieveDispatch(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/all/dispatch`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function retrieveSingleDispatch(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/single/dispatch`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function retrieveBlockedDispatch(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/block/dispatch`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function blockUser(payload: any, token?: string | null) {
  return axios.post(`/admin/block/user`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//  /admin/unblock/user
export function unBlockUser(payload: any, token?: string | null) {
  return axios.post(`/admin/unblock/user`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function blockDispatch(payload: any, token?: string | null) {
  return axios.post(`/admin/block/dispatch`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//

export function unBlockDispatch(payload: any, token?: string | null) {
  return axios.post(`/admin/unblock/dispatch`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//

export function dispatchRequest(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/unapproved/dispatch`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function retrieveTrip(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/trip`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function retrieveBookings(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/booking`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function approveRequest(payload: any, token?: string | null) {
  return axios.post(`/admin/approve/dispatch/request`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function rejectRequest(payload: any, token?: string | null) {
  return axios.post(`/admin/reject/dispatch/request`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function blockSubAdmin(payload: any, token?: string | null) {
  return axios.post(`/admin/block/subadmin`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function unblockSubAdmin(payload: any, token?: string | null) {
  return axios.post(`/admin/unblock/subadmin`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function retrieveBusiness(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/all/bussiness`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function retrieveSingleBusiness(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/single/bussiness`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function businessOrders(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/bussiness/order`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function businessDrivers(payload: any, token?: string | null) {
  return axios.post(`/admin/retrieve/bussiness/driver`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /
export function updateAdmin(payload: any, token?: string | null) {
  return axios.post(`/admin/update/admin`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function updatePassword(payload: any, token?: string) {
  return axios.post(`/admin/update/password`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function dashboard(payload: any, token?: string | null) {
  return axios.post(`/admin/dashboard`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function driverSupport(payload: any, token?: string | null) {
  return axios.post(`/admin/dashboard`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function userSupport(payload: any, token?: string | null) {
  return axios.post(`/admin/dashboard`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function dispatchTransactions(payload: any, token?: string | null) {
  //
  return axios.post(`/admin/dispatch/finance`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function userTransactions(payload: any, token?: string | null) {
  //
  return axios.post(`/admin/user/finance`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function sysBalance(payload: any, token?: string | null) {
  return axios.post(`/admin/system/balance`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function dispatchChatHistory(payload: any, token?: string | null) {
  return axios.post(`/admin/dispatch/chat/history`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function singleDispatchChat(payload: any, token?: string | null) {
  return axios.post(`/admin/dispatch/chat`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function userChatHistory(payload: any, token?: string | null) {
  return axios.post(`/admin/user/chat/history`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

//
export function singleUserChat(payload: any, token?: string | null) {
  return axios.post(`/admin/user/chat`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function updateSmtp(payload: any, token: string | null) {
  return axios.post(`/admin/update/smtp`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /admin/retrieve/user/emails
export function sendNewsLetter(payload: any, token: string | null) {
  return axios.post(
    `/admin/send/newsletter
  `,
    payload,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
}

// /admin/create/smtp
export function createSmtp(payload: any, token: string | null) {
  return axios.post(`/admin/create/smtp`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /admin/retrieve/smtp
export function retrieveSmtp(payload: any, token: string | null) {
  return axios.post(`/admin/retrieve/smtp`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// /user/track/order
export function trackOrder(payload: any) {
  return axios.post(`/user/track/order`, payload);
}

///admin/update/smtp
