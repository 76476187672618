import { useNavigate } from "react-router-dom";
import { Button } from "../../composables";
import { Container } from "../../container/Container.component";
import { RoleCard } from "./_components/RoleCard.component";
import { useGetQueries } from "../../../hooks/getQueries.hook";
import { retrieveRole } from "../../../utils/api";
import { useSelector } from "react-redux";
// import { retrieveRoles } from "../../../utils/api";
import { checkPermission } from "../../../hooks";
import { cn } from "../../../lib";
export default function Roles() {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state: any) => state.user);
  const { data, refetch } = useGetQueries(
    { adminid: currentUser?._id || currentUser?.id },
    retrieveRole
  );
  const status = checkPermission("CreateRoles");


  return (
    <Container>
      <div className="flex mb-4 sm:mb-6 items-center justify-between w-full">
        <h2 className="font-semibold text-base sm:text-lg">All Roles</h2>
        {status && (
          <Button
            onClick={() => navigate("/staffs/role/create")}
            className="text-gray-50 font-medium bg-dashx rounded-md"
          >
            Create Roles
          </Button>
        )}
      </div>

      <div className="border  rounded-md w-full ">
        <div className="py-4 flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between px-2 sm:px-4 w-full border-b">
          <h2 className=" font-semibold">Roles</h2>
        </div>
        <div className={cn("w-full font-semibold grid grid-cols-5 p-2 gap-2")}>
          <p>#</p>
          <p className="col-span-2">Role</p>

          {<p className="col-span-2 text-end w-full">Options</p>}
        </div>

        {Array.isArray(data) &&
          data.map((item, index) => (
            <RoleCard id={index} key={item} item={item} refetch={refetch} />
          ))}
      </div>
    </Container>
  );
}
