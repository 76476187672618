import { useForm } from "react-hook-form";
import { Button, PasswordField } from "../composables";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../utils/api";
import { errorToast } from "../../utils/errorToast";
import { Loader2Icon } from "lucide-react";
import { useToast } from "../../utils/toastContainer";
import { MdCheck } from "react-icons/md";
import { AuthLayout } from "../authLayout/AuthLayout.component";

type FormValue = {
  password: string;
};
export default function ResetPassword() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValue>({});

  async function onSubmit(value: FormValue) {
    await resetPassword({...value, token:"453u932"})
      .then((res) => {
     //   const { data } = res?.data;
        navigate("/");
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>Password Reset Successful, Log in</p>
            </div>
          ),
          className: "bg-green-200",
        });
      })
      .catch((error) => {
        errorToast(error, toast);
      });
  }

  return (
    <AuthLayout>
      <div className="w-full space-y-2 mb-10 sm:mb-20 text-center">
        <h2 className=" text-lg sm:text-2xl font-semibold ">
          Create New Password
        </h2>
        <p>
          {" "}
          We're here to help you regain access to your account. Please enter a
          new password below to secure your account.
        </p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-start justify-start  gap-y-4"
      >
        <PasswordField
          placeholder="*****"
          {...register("password")}
          error={errors.password?.message}
        />
        <Button
          disabled={isSubmitting}
          className="w-full bg-[#B847EF] mt-4 hover:bg-opacity-70 gap-x-2 text-white shadow-lg"
        >
          {isSubmitting && <Loader2Icon className="animate-spin text-[22px]" />}
          <span>Reset Password</span>
        </Button>
      </form>
    </AuthLayout>
  );
}
