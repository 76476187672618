import { MdStarRate, MdOutlineStarRate } from "react-icons/md";

export function Rating({rating}:{rating:number}) {

    const ratingButtons = [1, 2, 3, 4, 5];
    return (
        <div className="flex items-center ">
          {ratingButtons.map((value) => {
            return (
              <button key={value} className="text-xl text-zinc-700">
                {value <= rating ? (
                  <MdStarRate className="text-amber-500" />
                ) : (
                  <MdOutlineStarRate />
                )}
              </button>
            );
          })}
        </div>
    )
}