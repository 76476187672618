import { createContext, useState, ReactNode, useContext, useMemo } from "react";

type ProgressValue = {
  active: number;
  setActive: React.Dispatch<React.SetStateAction<number>>;
};

export const SettingContext = createContext<ProgressValue>({} as ProgressValue);

export function SettingContextProvider({ children }: { children: ReactNode }) {
  const [active, setActive] = useState(1);

  const contextValue = useMemo(() => {
    return { active, setActive };
  }, [active]);
  return (
    <SettingContext.Provider value={contextValue}>
      {children}
    </SettingContext.Provider>
  );
}

export const useSettingContextHook = () => {
  const context = useContext(SettingContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
