import { Button } from "../composables";
import { IoMdCall } from "react-icons/io";
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoLocationSharp, IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { Directions } from "../trackbooking/_components";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { trackOrder } from "../../utils/api";
import { phoneCall, whatsapp } from "../../utils/util";
import { cn } from "../../lib";
import axios from "axios";
import { Loader2Icon } from "lucide-react";
import { RiMenu4Fill } from "react-icons/ri";

interface AddressResponse {
  status: string;
  results: { formatted_address: string }[];
}
export default function OrderTracker() {
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [addresses, setAddresses] = useState<(string | null)[]>([]);
  const [isOrderDeatil, setOrderDetail] = useState(false);

  useEffect(() => {
    (async () => {
      if (search) {
        setLoading(true);
        await trackOrder({ orderid: search.replace("?order=", "") })
          .then((res) => {
            const { data } = res?.data;
            setData(data);
          })
          .catch((err) => {});
      }
    })();
  }, [search]);

  useEffect(() => {
    (async () => {
      if (data) {
        const coordinates = [
          {
            latitude: data?.order?.dispatchid?.cordinate?.latitude,
            longitude: data?.order?.dispatchid?.cordinate?.longitude,
          },
          {
            latitude: data?.order?.receivercordinate?.receiverlat,
            longitude: data?.order?.receivercordinate?.receiverlong,
          },
        ];

        const promises = coordinates.map(async ({ latitude, longitude }) => {
          return axios
            .get<AddressResponse>(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCgcr6Ebgd6okFz0LE0Jj74kz1oXMnHlTM`
            )
            .then((response) => {
              if (response.data.status === "OK") {
                return response.data.results[0]?.formatted_address || null;
              } else {
                console.error(
                  "Failed to retrieve address:",
                  response.data.status
                );
                return null;
              }
            })
            .catch((error) => {
              console.error("Error occurred while fetching address:", error);
              return null;
            });
        });

        const addresses = await Promise.all(promises);
        setAddresses(addresses);
        setLoading(false);
      }
    })();
  }, [data]);

  if (loading) {
    return (
      <div className="w-full h-full fixed backdrop-blur inset-0">
        <div className="absolute m-auto inset-0 w-fit h-fit flex flex-col items-center justify-center">
          <Loader2Icon size={30} className="animate-spin text-dashx" />
          <p className="font-semibold">Retrieving Data...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full  inset-0 fixed">
      <Button
        onClick={() => setOrderDetail((prev) => !prev)}
        className="absolute z-[300] right-3 top-3 w-fit h-fit bg-dashx p-1 px-2"
      >
        <RiMenu4Fill className="text-2xl text-gray-50" />
      </Button>
      {/**map */}
      <APIProvider apiKey={"AIzaSyCgcr6Ebgd6okFz0LE0Jj74kz1oXMnHlTM"}>
        <Map
          key={`${addresses[0] ?? ""}_${addresses[1] ?? ""}`} // ${trip?.senderaddress}_${trip?.receiveraddress}
          fullscreenControl={false}
          center={{
            lat: parseFloat(data?.order?.receivercordinate?.receiverlat ?? 0.0),
            lng: parseFloat(
              data?.order?.receivercordinate?.receiverlong ?? 0.0
            ),
          }} // receiverPosition
          zoom={40}
        >
          {/* Marker for starting point */}
          {addresses?.[0] && (
            <Marker
              key="start"
              position={{
                lat: parseFloat(data?.order?.dispatchid?.cordinate?.latitude),
                lng: parseFloat(data?.order?.dispatchid?.cordinate?.longitude),
              }}
              label="" // Custom label for the marker
              icon={{
                url: "https://firebasestorage.googleapis.com/v0/b/preem-whatsapp-cloning-cd897.appspot.com/o/images%2Fellipse-removebg-preview.png?alt=media&token=56a1f7f9-8dc1-4f18-b495-9213eb6dfcc8", // Custom marker icon
                scaledSize: new window.google.maps.Size(24, 24), // Adjust size if needed
                anchor: new window.google.maps.Point(12, 24)
              }}

            />
          )}
          
          {/* Marker for destination */}
          {addresses?.[1] && (
            <Marker
              key="end"
              position={{
                lat: parseFloat(data?.order?.receivercordinate?.receiverlat),
                lng: parseFloat(data?.order?.receivercordinate?.receiverlong),
              }}
              label="" // Custom label for the marker
              icon={{
                url: " https://firebasestorage.googleapis.com/v0/b/preem-whatsapp-cloning-cd897.appspot.com/o/images%2Fcircle-removebg-preview.png?alt=media&token=9c427e4a-4c51-406c-b451-c1649ba5d716", // Custom marker icon
                scaledSize: new window.google.maps.Size(24, 24), // Adjust size if needed
              }}
            />
          )}
          {addresses?.length > 0 && (
            <Directions
              senderAddress={addresses[0] ? addresses[0] : ""}
              receiverAddress={addresses[1] ? addresses[1] : ""}
            />
          )}
        </Map>
      </APIProvider>
      {/** detail */}
      <div
        className={cn(
          "z-[300] list swipeUp  w-full max-w-2xl h-fit bottom-0 inset-x-0 px-4 py-6 absolute mx-auto rounded-t-3xl sm:rounded-t-lg bg-white hidden shadow",
          isOrderDeatil && "block"
        )}
      >
        <button
          onClick={() => setOrderDetail(false)}
          className=" absolute sm:hidden block inset-x-0 mx-auto transition-all duration-300 transform max-h-[85%] overflow-y-auto top-1 bg-gray-100 rounded-3xl w-24 h-2"
        ></button>
        <div className="w-full items-center grid grid-cols-5 gap-3 mb-3">
          <div className="w-full grid col-span-3 items-center grid-cols-6 gap-12 sm:gap-3 ">
            {data?.order?.dispatchid?.photo ? (
              <div className="w-10 h-10 rounded-full overflow-hidden">
                <img
                  src={data?.order?.dispatchid?.photo}
                  alt=""
                  className="object-cover w-full h-full"
                />
              </div>
            ) : (
              <div className="w-10 h-10 rounded-full bg-gray-300"></div>
            )}

           
              <p className="font-semibold col-span-5 capitalize w-full whitespace-nowrap text-ellipsis overflow-hidden">
                {data?.order?.dispatchid?.name ?? ""}
              </p>
      
          </div>
          <div className="flex w-full items-end justify-end">
          <Button
            onClick={() => whatsapp(data?.order?.dispatchid?.phone)}
            className="bg-dashx  h-10 w-10 px-0 rounded-full text-gray-50"
          >
            <IoChatbubbleEllipsesOutline className="" size={20} />
          </Button>
            </div>
         
          <div className="flex w-full items-end justify-end">
          <Button
            onClick={() => phoneCall(data?.order?.dispatchid?.phone)}
            className="bg-dashx h-10 w-10 px-0 rounded-full text-gray-50"
          >
            <IoMdCall className="" size={20} />
          </Button>
          </div>
        </div>

        <div className="rounded-xl border p-3 gap-y-4 shadow w-full flex flex-col items-start justify-start">
          {addresses?.length > 0 && (
            <div className="flex flex-col items-start mb-3 justify-start gap-y-2">
              <div className="grid grid-cols-8">
                <IoLocationSharp className="text-xl text-dashx" />
                <p className="col-span-7">{addresses[0]}</p>
              </div>
              <div className="grid grid-cols-8">
                <IoMdRadioButtonOn className="text-xl text-dashx" />
                <p className="col-span-7"> {addresses[1]}</p>
              </div>
            </div>
          )}

          <p className=" text-gray-400">Order Detail</p>
          <div className="w-full space-y-2">
            <div className="w-full flex items-center justify-between ">
              <p className="text-dashx font-medium">Order Code</p>

              <p className="text-sm sm:text-[15px] font-semibold">
                {data?.ordercode?.order_code ?? ""}
              </p>
            </div>
            <div className="w-full flex items-center justify-between ">
              <p className="text-dashx font-medium">Order Status</p>
              <button
                className={cn(
                  "bg-gray-200 rounded-md px-2 w-fit flex items-center justify-centerh-fit py-1 text-[11px] sm:text-xs",
                  data?.order?.order_status === "delivered" &&
                    "text-white bg-dashx",
                  data?.order?.order_status === "pickup" &&
                    "text-white bg-yellow-500",
                  data?.order?.order_status === "accepted" &&
                    "text-white bg-blue-500",
                  data?.order?.order_status === "shipping" &&
                    "text-white bg-green-600"
                )}
              >
                {data?.order?.order_status}
              </button>
            </div>
          </div>

          <p className=" text-gray-400">Product Detail</p>
          <div className="w-full space-y-2">
            <div className="flex items-center justify-between w-full">
              <p className="text-sm sm:text-[15px] font-semibold text-dashx">
                {data?.order?.productname ?? ""}
              </p>

              <p className="text-sm sm:text-[15px] font-semibold">{`N${parseInt(
                data?.order?.total_fee || 0
              )?.toLocaleString()}`}</p>
            </div>
          </div>

          <p className=" text-gray-400">Ride Detail</p>
          <div className="w-full space-y-2">
            <div className="flex items-center justify-between w-full">
              <p className="text-dashx font-medium">Driver Phone No.</p>

              <p className="text-sm sm:text-[15px] font-semibold">
                {data?.order?.dispatchid?.phone ?? ""}
              </p>
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-dashx font-medium">Vehicle Type</p>
              <p className="text-sm sm:text-[15px] font-semibold">
                {data?.order?.dispatchid?.vehicle?.vehicle_type ||
                  data?.order?.vehicle_type ||
                  ""}
              </p>
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-dashx font-medium">Vehicle Number</p>

              <p className="text-sm sm:text-[15px] font-semibold">
                {data?.order?.dispatchid?.vehicle?.vehicle_number ?? ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// dispatchid?.cordinate?.latitude receivercordinate?.receiverlat
// dispatchid?.cordinate?.longitude  receivercordinate?.receiverlong


//  https://firebasestorage.googleapis.com/v0/b/preem-whatsapp-cloning-cd897.appspot.com/o/images%2Fcircle-removebg-preview.png?alt=media&token=9c427e4a-4c51-406c-b451-c1649ba5d716
// https://firebasestorage.googleapis.com/v0/b/preem-whatsapp-cloning-cd897.appspot.com/o/images%2Fellipse-removebg-preview.png?alt=media&token=56a1f7f9-8dc1-4f18-b495-9213eb6dfcc8