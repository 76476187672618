"use client";

import {
  createContext,
  SetStateAction,
  useState,
  useContext,
  Dispatch,
  useMemo,
} from "react";
import { SideBarNavType } from "../types/global.interface";

type TAdminPermission = {
  reformedNavLink: SideBarNavType[] | null;
  setReformedNavLink: Dispatch<SetStateAction<SideBarNavType[] | null>>;
};
export const AdminPermissionContext = createContext<TAdminPermission>(
  {} as TAdminPermission
);

export function AdminPermissionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [reformedNavLink, setReformedNavLink] = useState<
    SideBarNavType[] | null
  >([]);

  // memoized to avoid re-rendering
  const contextValue = useMemo(() => {
    return {
      reformedNavLink,
      setReformedNavLink,
    };
  }, [reformedNavLink, setReformedNavLink]);

  return (
    <AdminPermissionContext.Provider value={contextValue}>
      {children}
    </AdminPermissionContext.Provider>
  );
}

export const useReformedNavLink = () => {
  const context = useContext(AdminPermissionContext);
  if (!context) {
    throw new Error(
      "useNavLinks must be used within an AdminPermissionProvider"
    );
  }
  return context;
};
