import { settingTabs } from "../../../../constant/constant";
import { Button } from "../../../composables";
import { Container } from "../../../container/Container.component";
import { cn } from "../../../../lib";
import { useSettingContextHook } from "../../setingContext/SettingContext.context";
import { Profile } from "../profile/Profile.component";
import { Password } from "../password/Password.component";


enum ActiveSettingTab {
  PROFILE_TAB = 1,
  PASSWORD_TAB,
}

export function SettingLayout() {
  const { active, setActive } = useSettingContextHook();

  ///  NOTIFICATION_TAB,   {active === ActiveSettingTab.NOTIFICATION_TAB && <Roles />}
  return (
    <Container>
      <h2 className="font-semibold text-base sm:text-xl mb-6">Settings</h2>
      <div className="flex flex-col md:flex-row items-center md:items-start gap-x-6">
        <div className="md:w-[200px] w-full bg-white shadow-lg rounded-lg h-fit py-6 px-4 ">
          <ul className="flex flex-col gap-y-0 items-start px-4 justify-start w-full">
            {settingTabs.map(({ name, id, icon }) => {
              return (
                <li key={name} className="w-full">
                  <Button
                    onClick={() => {
                      setActive(id);
                    }}
                    className={cn(
                      "p-3  flex  items-center justify-start text-dashx gap-x-2 w-full",
                      active === id &&
                        " text-gray-50 bg-dashx rounded-3xl shadow-xl"
                    )}
                  >
                    {icon}
                    <span>{name}</span>
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="w-full h-full shadow-lg bg-white rounded-lg py-6 px-2 sm:px-4">
          {active === ActiveSettingTab.PROFILE_TAB && <Profile />}
          {active === ActiveSettingTab.PASSWORD_TAB && <Password />}
        </div>
      </div>
    </Container>
  );
}
