import { useState } from "react";
import { Button } from "../../../composables";
import { cn } from "../../../../lib";
import profile from "../../../../assets/png/profile.png";

export function BookingDetail({ trip }: { trip: any }) {
  const [active, setActive] = useState(1);
  return (
    <div className="mx-auto bg-white w-[90%]  p-4 rounded-lg shadow-lg">
      <div className="flex items-center justify-around w-full border-b mb-6 text-gray-500">
        <Button
          onClick={() => {
            setActive(1);
          }}
          className={cn(
            "py-2 rounded-none",
            active === 1 && "border-b-2 font-medium border-dashx"
          )}
        >
          Trip Details
        </Button>
        <Button
          onClick={() => {
            setActive(2);
          }}
          className={cn(
            "py-2 rounded-none",
            active === 2 && "border-b-2 font-medium border-dashx"
          )}
        >
          Driver Info
        </Button>
        <Button
          onClick={() => {
            setActive(3);
          }}
          className={cn(
            "py-2 rounded-none",
            active === 3 && "border-b-2 font-medium border-dashx"
          )}
        >
          Vehicle Details
        </Button>
      </div>

      {active === 1 && <TripDetail trip={trip} />}
      {active === 2 && <DriverInfo trip={trip} />}
      {active === 3 && <VehicleDetail trip={trip} />}
    </div>
  );
}

function TripDetail({ trip }: { trip: any }) {
  return (
    <div className="w-full grid grid-cols-2 gap-4 items-center sm:grid-cols-3 md:grid-cols-4">
      <Widget title="Trip ID" value={trip?._id?.slice(0, 5) ?? ""} />

      <Widget
        title="Order status"
        value={trip?.order_completed ? "Completed" : "Pending"}
      />

      <Widget title="Delivery Status" value={trip?.order_status ?? ""} />
     {/* <Widget title="Drop off" value="lorem ipsum" /> */}
    </div>
  );
}

function VehicleDetail({ trip }: { trip: any }) {
  return (
    <div className="w-full flex items-start justify-start gap-x-4">
      <Widget
        title="Vehicle Number"
        value={trip?.vehicle?.vehicle_number ?? ""}
      />
      <Widget title="Vehicle Type" value={trip?.vehicle?.vehicle_type ?? ""} />
    </div>
  );
}

function DriverInfo({ trip }: { trip: any }) {
  return (
    <div className="w-full flex flex-col items-start justify-start gap-y-4">
      <div className="flex items-center  gap-x-2">
        <div className="h-24 w-24 overflow-hidden rounded-xl">
          <img src={profile} className="w-full h-full" alt="" />
        </div>
        <div className="space-y-3">
          <p className="font-semibold">{trip?.receivername ?? ""}</p>
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-4 items-center sm:grid-cols-3 md:grid-cols-4">
        <Widget title="Driver ID" value={trip?._id?.slice(0, 5) ?? ""} />
        <Widget title="Mobile Number" value={trip?.receiverphone ?? ""} />
        <Widget title="Trips" value="10" />
        <Widget title="Email" value={trip?.email ?? ""} />
      </div>
    </div>
  );
}
function Widget({
  title,
  value,
  className,
}: {
  title: string;
  value: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "w-full flex flex-col items-start justify-start gap-y-2 py-1",
        className
      )}
    >
      <p className=" font-semibold">{title}</p>
      <p className="flex items-end justify-end">{value}</p>
    </div>
  );
}
