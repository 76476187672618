export function formatDate(originalTimestamp: string) {
  const dateObject: Date = new Date(originalTimestamp);

  // Format the date
  const year: number = dateObject.getUTCFullYear();
  const month: string = (dateObject.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0");
  const day: string = dateObject.getUTCDate().toString().padStart(2, "0");
  const hours: string = dateObject.getUTCHours().toString().padStart(2, "0");
  const minutes: string = dateObject
    .getUTCMinutes()
    .toString()
    .padStart(2, "0");
  const ampm: string = dateObject.getUTCHours() >= 12 ? "PM" : "AM";

  return {
    date: `${year}-${day}-${month}`,
    time: `${hours}:${minutes}${ampm}`,
  };
}

