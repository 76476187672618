import { InputField } from "../composables";
import { Container } from "../container/Container.component";
import { IoIosSearch } from "react-icons/io";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { retrieveBusiness } from "../../utils/api";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";
import { BusinessWidget } from "./_components";
import { checkPermission } from "../../hooks";
import { cn } from "../../lib";
export default function Business() {
  const { currentUser } = useSelector((state: any) => state.user);
  const status = checkPermission("SetBusinessStatus");
  const { data, loading } = useGetQueries(
    { adminid: currentUser?.id || currentUser?._id },
    retrieveBusiness
  );

  return (
    <Container>
      <h2 className="font-semibold text-base sm:text-xl mb-4">Business</h2>

      <div className="dashboard-scroll-style w-full h-fit pb-3 overflow-x-auto">
        <div className="w-full min-w-[950px] flex flex-col gap-y-3">
          <div className="w-full  border-b p-2 flex items-center gap-x-2">
            <div className="relative w-[300px] rounded-xl h-11">
              <InputField
                placeholder="search"
                type="search"
                className="w-full rounded-xl h-11 border-gray-300 pl-10"
              />
              <IoIosSearch className="text-[22px] text-gray-300 absolute top-3 left-3 " />
            </div>
            {/* <SelectField placeHolder="Status" options={status} width="150px" />*/}
            <InputField
              placeholder="date"
              type="date"
              className="h-11 w-[150px] border-gray-300 rounded-xl"
            />
          </div>

          <div
            className={cn(
              "w-full grid p-2 font-semibold grid-cols-7 gap-4 items-center",
              status && "grid-cols-8"
            )}
          >
            <p>ID</p>
            <p className="w-full col-span-2">Drivers</p>
            <p>Mobile No</p>
            <p className="w-full col-span-2">Email</p>
            {status && <p>Status</p>}
            <p>Actions</p>
          </div>
          {loading && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <Loader2Icon size={48} className=" animate-spin text-gray-600" />
            </div>
          )}
          {!loading && data?.length === 0 && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <p className="text-red-600">- No Data -</p>
            </div>
          )}
          {Array.isArray(data) &&
            data?.map(
              (
                { name, vehicle, _id, email, phone, photo, dispatch_blocked },
                idx
              ) => (
                <BusinessWidget
                  vehicleType={vehicle.vehicle_type}
                  key={email}
                  serialNo={idx}
                  id={_id}
                  name={name}
                  email={email}
                  phone={phone}
                  photo={photo}
                  user_blocked={dispatch_blocked}
                />
              )
            )}
        </div>
      </div>
    </Container>
  );
}
