import { Container } from "../container/Container.component";
import { RequestWidget } from "./_components";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { dispatchRequest } from "../../utils/api";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";

export default function Requests() {
  const {currentUser} = useSelector((state:any) => state.user)
  const {data, loading} = useGetQueries({adminid: currentUser?.id || currentUser?._id}, dispatchRequest)
  

  return (
    <Container>
      <h2 className="font-semibold text-base sm:text-xl mb-6">Requests</h2>

      
      <div className="dashboard-scroll-style w-full h-fit pb-3 overflow-x-auto">
        <div className="w-full min-w-[1000px] flex flex-col gap-y-3">
          <div className="w-full grid p-2 font-semibold grid-cols-7 gap-4 items-center">
            <p className="w-full col-span-2">Name</p>
            <p>Email</p>
            <p>Mobile No</p>
            <p>Vehicle</p>
            <p>Plate No</p>
            <p>Actions</p>
          </div>
          {loading && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <Loader2Icon size={48} className=" animate-spin text-gray-600" />
            </div>
          )}
          {!loading && data?.length === 0 && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <p className="text-red-600">- No Data -</p>
            </div>
          )}
          {Array.isArray(data) && data?.map((item, idx) => (
            <RequestWidget key={item.name}  item={item} />
          ))}
        </div>
      </div>
    </Container>
  );
}
