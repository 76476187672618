import { useForm } from "react-hook-form";
import { Button, InputField } from "../../../composables";
import { useSelector } from "react-redux";
import { updatePassword } from "../../../../utils/api";
import { useToast } from "../../../../utils/toastContainer";
import { MdCheck } from "react-icons/md";
import { Loader2Icon } from "lucide-react";
import { errorToast } from "../../../../utils/errorToast";

type FormValue = {
  newpassword: string;
  currentpassword: string;
  adminid: string;
};
export function Password() {
  const { currentUser, token } = useSelector((state: any) => state.user);
  const { toast } = useToast();
  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValue>({
    defaultValues: {
      adminid: currentUser?.id || currentUser?._id,
    },
  });

  async function onSubmit(values: FormValue) {
    await updatePassword(values, token)
      .then((res) => {
        // const { data } = res?.data;

        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>User Password Updated Successfully</p>
            </div>
          ),
          className: "bg-green-200",
        });
        reset();
      })
      .catch((error) => {
        errorToast(error, toast);
      });
  }

  return (
    <div>
      <div className="flex items-start flex-col justify-start w-full gap-y-6 sm:gap-y-8">
        <div className="w-full border-b py-2 space-y-1 flex-col flex items-start justify-start">
          <h2 className="font-semibold text-base sm:text-xl">Password</h2>
          <p>
            We will email you a confirmation when changing your password, so
            please expect that email after submitting.
          </p>
        </div>
        <h2 className="font-semibold   text-base sm:text-xl">
          Change Password
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full grid grid-cols-1 gap-4 sm:grid-cols-2"
        >
          <InputField
            type="password"
            placeholder="*****"
            hasLabel
            {...register("currentpassword")}
            label="Current Password"
          />
          <InputField
            placeholder="*****"
            hasLabel
            type="password"
            {...register("newpassword")}
            label="New Password"
          />

          <Button
            disabled={isSubmitting}
            className="mt-6 w-full sm:w-[200px] rounded-lg bg-dashx text-white font-medium"
          >
            {isSubmitting && (
              <Loader2Icon className="animate-spin text-[22px]" />
            )}
            Update Password
          </Button>
        </form>
      </div>
    </div>
  );
}
