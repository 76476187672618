import { PiDotsThreeVertical } from "react-icons/pi";

export function NotificationCard({ image }: { image: string }) {
  return (
    <div className="flex items-center w-full justify-between">
      <div className="w-full flex  p-2 gap-x-2  items-center justify-center">
        <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
          <img src={image} alt="" className="w-full h-full" />
        </div>

        <div className="w-full space-y-1">
          <p className="font-semibold">Mc Donald</p>

          <p className="w-full line-clamp-2 font-medium">What's up</p>
        </div>
      </div>
      <PiDotsThreeVertical className="text-[22px]" />
    </div>
  );
}
