import user from "../../../../assets/png/user.png";
import { useState } from "react";
import { Button } from "../../../composables";
import { RequestDetail } from "./RequestDetail.component";

export function RequestWidget({item}:{item:any}) {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen((prev) => !prev);
  }
  return (
    <>
      <div className="grid grid-cols-7  border-b gap-4 items-center py-3 px-2">
        <div className="col-span-2 grid items-center grid-cols-4">
        <div className="w-[40px] h-[40px]">
        <img src={item?.photo ?? user} alt="" className="w-full h-full rounded-full" />
        </div>
          <p className="col-span-3 line-clamp-1">{item?.name ?? ""}</p>
        </div>
        <p className="w-full line-clamp-1">{item?.email ?? ""}</p>
        <p>{item?.phone ?? ""}</p>

        <p>{item?.vehicle?.vehicle_type}</p>
        <p>{item?.vehicle?.vehicle_number}</p>
        <Button
        onClick={onClose}
        className="underline w-fit px-1 bg-transparent text-dashx">
          View Details
        </Button>
      </div>

      {isOpen && <RequestDetail item={item} close={onClose} />}
    </>
  );
}
