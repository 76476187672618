import React from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const { token } = useSelector((state: any) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  if (token) {
    return <>{children}</>;
  } else {
    toast.error("Access Denied, login required");
    navigate("/", { state: { path: location.pathname } });
    return null;
  }
};
