import { FaCar } from "react-icons/fa";
import { FaVanShuttle } from "react-icons/fa6";
import { FaTruck } from "react-icons/fa6";
import { RiEBike2Fill } from "react-icons/ri";
import { TbDiscount2 } from "react-icons/tb";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { InputField, Button } from "../../../composables";
import { useGetQueries } from "../../../../hooks/getQueries.hook";
import { retreivePricing, updatePricing } from "../../../../utils/api";
import { useEffect } from "react";
import { MdCheck } from "react-icons/md";
import { Loader2Icon } from "lucide-react";
import { errorToast } from "../../../../utils/errorToast";
import { useToast } from "../../../../utils/toastContainer";
import { Container } from "../../../container/Container.component";

type FormValue = {
  adminid: string;
  bike: number;
  car: number;
  comision: number;
  small_truck: number;
  big_truck: number;
  medium_truck: number;
  van: number;
};

export default function Pricing() {
  const { currentUser, token } = useSelector((state: any) => state.user);
  const { toast } = useToast();
  const { data, loading, refetch } = useGetQueries(
    { adminid: currentUser?.id || currentUser?._id },
    retreivePricing
  );
  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValue>({
    defaultValues: {
      adminid: currentUser?.id || currentUser?._id,
      bike: 0,
      small_truck: 0,
      medium_truck:0,
      big_truck:0,
      car: 0,
      comision: 0,
      van: 0,
  
    },
  });

  // Use useEffect to set form default values when data is loaded
  useEffect(() => {
    if (!loading) {
      reset({
        adminid: currentUser?.id || currentUser?._id,
        bike: data[0].bike,
        small_truck: data[0].small_truck,
        medium_truck: data[0].medium_truck,
        big_truck: data[0].big_truck,
        car: data[0].car,
        comision: data[0].comision,
        van: data[0].van,
       
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, currentUser, reset]);

  async function onSubmit(values: FormValue) {
    await updatePricing(values, token)
      .then((res) => {
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>Price Updated Successfully</p>
            </div>
          ),
          className: "bg-green-200",
        });
        refetch()
       // window.location.reload();
      })

      .catch((err) => {
        errorToast(err, toast);
      });
  }
  return (
    <Container>
      <div className="w-full border-b py-2  flex items-start flex-col justify-start gap-y-2">
        <h2 className="font-semibold text-base sm:text-xl">Pricing</h2>
        <p>
          Click the <span className="font-semibold">Add Price</span> everytime
          you update the pricing
        </p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full sm:w-[80%] xl:w-[60%] flex flex-col items-start justify-start p-2"
      >
        <div className="flex p-2 items-center justify-between w-full font-semibold">
          <p>Name</p>
          <p>Price</p>
        </div>

        <div className="flex p-2 border-b items-center justify-between w-full">
          <div className="flex items-center gap-x-2">
            <RiEBike2Fill className="text-4xl text-dashx" />
            <p>Bike</p>
          </div>

          <InputField
            className="w-[100px] h-10"
            parentClassName="w-[100px]"
            type="number"
            {...register("bike")}
          />
        </div>
        <div className="flex p-2 border-b items-center justify-between w-full">
          <div className="flex items-center gap-x-2">
            <FaCar className="text-4xl text-dashx" />
            <p>Car</p>
          </div>

          <InputField
            className="w-[100px] h-10"
            parentClassName="w-[100px]"
            type="number"
            {...register("car")}
          />
        </div>
        <div className="flex p-2 border-b items-center justify-between w-full">
          <div className="flex items-center gap-x-2">
            <FaVanShuttle className="text-4xl text-dashx" />
            <p>Van</p>
          </div>

          <InputField
            className="w-[100px] h-10"
            parentClassName="w-[100px]"
            type="number"
            {...register("van")}
          />
        </div>
        <div className="flex p-2 border-b items-center justify-between w-full">
          <div className="flex items-center gap-x-2">
            <FaTruck className="text-4xl text-dashx" />
            <p>Small Truck</p>
          </div>

          <InputField
            className="w-[100px] h-10"
            parentClassName="w-[100px]"
            type="number"
            {...register("small_truck")}
          />
        </div>
        <div className="flex p-2 border-b items-center justify-between w-full">
          <div className="flex items-center gap-x-2">
            <FaTruck className="text-4xl text-dashx" />
            <p>Medium Truck</p>
          </div>

          <InputField
            className="w-[100px] h-10"
            parentClassName="w-[100px]"
            type="number"
            {...register("medium_truck")}
          />
        </div>
        <div className="flex p-2 border-b items-center justify-between w-full">
          <div className="flex items-center gap-x-2">
            <FaTruck className="text-4xl text-dashx" />
            <p>Big Truck</p>
          </div>

          <InputField
            className="w-[100px] h-10"
            parentClassName="w-[100px]"
            type="number"
            {...register("big_truck")}
          />
        </div>
        <div className="flex p-2 border-b items-center justify-between w-full">
          <div className="flex items-center gap-x-2">
            <TbDiscount2 className="text-4xl text-dashx" />
            <p>Commission</p>
          </div>

          <InputField
            className="w-[100px] h-10"
            parentClassName="w-[100px]"
            type="number"
            {...register("comision")}
          />
        </div>
   

        <div className="w-full flex items-end justify-end mt-6">
          <Button
            disabled={isSubmitting}
            type="submit"
            className="bg-dashx rounded-lg text-white w-[150px] px-6 h-11 font-medium"
          >
            {isSubmitting && <Loader2Icon className="animate-spin" />}
            <span>Add Price</span>
          </Button>
        </div>
      </form>
    </Container>
  );
}
