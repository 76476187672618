import { cn } from "../../../../lib";
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { PiDotsThreeVertical } from "react-icons/pi";
export function BookingCard({
  itemId,
  activeId,
  sender,
  receiver,
  status,
}: {
  status: string;
  sender: string;
  receiver: string;
 activeId:string
 itemId:string
}) {
  return (
    <div
      className={cn(
        "bg-white flex cursor-pointer flex-col shadow-lg hover:border-l-4 hover:border-dashx w-full gap-y-3 items-start py-4 px-3 rounded-lg",
        activeId === itemId && "border-l-4 border-dashx"
      )}
    >
      <div className="mb-3 flex items-center justify-between w-full">
        <p className="font-medium text-base sm:text-lg">TRMB</p>
        <button
          className={cn(
            "bg-gray-200 rounded-md px-2 py-2",
            status === "delivered" && "text-white bg-dashx",
            status === "pickup" && "text-white bg-yellow-500",
            status === "accepted" && "text-white bg-blue-500",
            status === "shipping" && "text-white bg-green-600",

          )}
        >
          {status}
        </button>
      </div>
      <div className="w-full flex flex-col items-start justify-start">
        <div className="flex items-center gap-x-2">
          <IoMdRadioButtonOn className="text-dashx text-3xl" />
          <p>{sender}</p>
        </div>

        <div className="w-[30px] flex items-center justify-center">
          <div className="flex flex-col gap-y-0 items-center">
            <PiDotsThreeVertical className="text-[22px]" />
            <PiDotsThreeVertical className="text-[22px]" />
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <IoLocationSharp className="text-dashx text-3xl" />
          <p>{receiver}</p>
        </div>
      </div>
    </div>
  );
}
