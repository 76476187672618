import { SettingTabType, SideBarNavType } from "../types/global.interface";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { AiFillMessage } from "react-icons/ai";
import { FaUserTie, FaListAlt } from "react-icons/fa";
import { FaUserSecret } from "react-icons/fa";
import { PiFilesFill } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { Store } from "lucide-react";
import { PiUserSquareFill } from "react-icons/pi";
import { IoPricetags } from "react-icons/io5";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { RxDot } from "react-icons/rx";
import { GrUserWorker } from "react-icons/gr";

export const navLinks: SideBarNavType[] = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: <MdOutlineSpaceDashboard className="text-[22px]" />,
  },
  {
    name: "Users",
    link: "/customers",
    icon: <FaUserTie className="text-[22px]" />,
  },
  {
    name: "Driver",
    link: "/drivers",
    icon: <FaUserSecret className="text-[22px]" />,
  },
  {
    name: "Business",
    link: "/business",
    icon: <MdOutlineBusinessCenter className="text-[22px]" />,
  },
  {
    name: "Requests",
    link: "/requests",
    icon: <PiFilesFill className="text-[22px]" />,
  },
  {
    name: "Bookings",
    link: "/bookings",
    icon: <FaListAlt className="text-[22px]" />,
  },
  {
    name: "Track Bookings",
    link: "/track-booking",
    icon: <FaListAlt className="text-[22px]" />,
  },
  {
    name: "Support Chat",
    link: "/chat",
    icon: <AiFillMessage className="text-[22px]" />,
  },
  {
    name: "Transactions",
    link: "/transactions",
    icon: <FaListAlt className="text-[22px]" />,
  },
  {
    name: "Pricing",
    link: "/pricing",
    icon: <IoPricetags className="text-[22px]" />,
  },
  {
    name: "NewsLetters",
    link: "/newsLetters",
    icon: <Store size={22} />,
  },
  {
    name: "Settings",
    link: "/settings",
    icon: <IoSettingsOutline className="text-[22px]" />,
    tab: [
      {
        name: "General Settings",
        link: "/settings",
        icon: <RxDot className="text-[22px]" />,
      },
      {
        name: "SMTP Settings",
        link: "/settings/smtp-settings",
        icon: <RxDot className="text-[22px]" />,
      },
    ]
  },
  {
    name: "Staffs",
    link: "/staffs",
    icon: <GrUserWorker className="text-[22px]" />,
    tab: [
      {
        name: "All Staffs",
        link: "/staffs",
        icon: <RxDot className="text-[22px]" />,
      },
      {
        name: "Roles",
        link: "/staffs/role",
        icon: <RxDot className="text-[22px]" />,
      },
    ],
  },
];

export const settingTabs: SettingTabType[] = [
  {
    id: 1,
    name: "Profile",
    icon: <PiUserSquareFill className="text-[22px]" />,
  },
  {
    id: 2,
    name: "Password",
    icon: <RiLockPasswordFill className="text-[22px]" />,
  },

];
