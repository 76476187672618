import { useMemo } from "react";
import { cn } from "../../../lib";
import { formatDate } from "../../../utils/formatDate";
import { LiaUserCircleSolid } from "react-icons/lia";

export function BookingWidget({
  item,
  serialNo,
}: {
  serialNo: number;
  item: any;
}) {
  const formattedDate = useMemo(
    () => formatDate(item?.createdAt),
    [item?.createdAt]
  );

  return (
    <>
      <div className="grid grid-cols-9  border-b gap-4 items-center py-3 px-2">
        <p className="w-full col-span-2 line-clamp-1">{item?._id}</p>
        <div className="col-span-2 grid items-center grid-cols-4">
          <LiaUserCircleSolid className="text-4xl text-dashx" />
          <p className="col-span-3 line-clamp-1">{item?.sendername ?? ""}</p>
        </div>
        <div className="text-start space-y-1">
          <p>{formattedDate?.date ?? ""}</p>
          <p>{formattedDate?.time ?? ""}</p>
        </div>
        <div className="col-span-2 grid items-center grid-cols-4">
          <LiaUserCircleSolid className="text-4xl text-dashx" />
          <p className="col-span-3 line-clamp-1">{item?.receivername ?? ""}</p>
        </div>
        <p
          className={cn(
            " rounded-md text-center  text-red-600",
            item?.order_status === "delivered" && " text-dashx",
            item?.order_status === "pickup" && " text-yellow-500",
            item?.order_status === "shipping" && " text-green-600"
          )}
        >
          {item?.order_status ?? ""}
        </p>
        <p>{`₦${(item?.delivery_fee ?? 0).toLocaleString()}`}</p>
      </div>
    </>
  );
}
