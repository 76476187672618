import { Container } from "../container/Container.component";
import { useMemo, useState, useEffect } from "react";
import { cn } from "../../lib";
import { Button } from "../composables";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";
import { dispatchTransactions, userTransactions } from "../../utils/api";
import { formatDate } from "../../utils/formatDate";

export default function Transactions() {
  const { currentUser } = useSelector((state: any) => state.user);
  const [active, setActive] = useState("drivers");
  const { data, loading, refetch } = useGetQueries(
    { adminid: currentUser?.id || currentUser?._id },
    active === "drivers" ? dispatchTransactions : userTransactions
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <Container>
      <h2 className="font-semibold text-base sm:text-xl mb-4">
        Transactions History
      </h2>

      <div className="w-full mb-3 py-3 flex items-center gap-x-4 sm:gap-x-8">
        <Button
          onClick={() => {
            setActive("drivers");
          }}
          className={cn(
            "font-medium rounded-3xl bg-gray-200 h-11",
            active === "drivers" && "bg-dashx text-white"
          )}
        >
          Drivers History
        </Button>
        <Button
          onClick={() => {
            setActive("users");
          }}
          className={cn(
            "font-medium bg-gray-200 rounded-3xl h-11",
            active === "users" && "bg-dashx text-white"
          )}
        >
          Users History
        </Button>
      </div>

      <div className="dashboard-scroll-style w-full h-fit pb-3 overflow-x-auto">
        <div className="w-full min-w-[800px] flex flex-col gap-y-3">
          <div className="w-full grid p-2 font-semibold grid-cols-7 gap-4 items-center">
            <p>ID</p>
            <p className="w-full col-span-2">Name</p>
            <p className="col-span-2">Email</p>
            <p>Date</p>
            <p>Amount</p>
          </div>
          {loading && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <Loader2Icon size={48} className=" animate-spin text-gray-600" />
            </div>
          )}
          {!loading && data?.length === 0 && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <p className="text-red-600">- No Data -</p>
            </div>
          )}

          {Array.isArray(data) &&
            data.slice().reverse().map((item, idx) => {
              const { _id } = item;
              return <Widget key={_id} idx={idx} item={item} />;
            })}
        </div>
      </div>
    </Container>
  );
}

function Widget({ item, idx }: { idx: number; item: any }) {
  const formattedDate = useMemo(
    () => formatDate(item?.createdAt ?? "0"),
    [item?.createdAt]
  );
  return (
    <div className="grid grid-cols-7 hover:bg-gray-100 border-b gap-4 items-center py-3 px-2">
      <p>{idx + 1}</p>
      <p className="w-full line-clamp-1 col-span-2">
        {item?.dispatchid?.name ?? item?.userid?.name ?? ""}
      </p>
      <p className="col-span-2 w-full line-clamp-1">
        {item?.dispatchid?.email ?? item?.userid?.email ?? ""}
      </p>
      <div>
        <p>{formattedDate?.date ?? ""}</p>
        <p>{formattedDate?.time ?? ""}</p>
      </div>
      <p>{`₦${(item?.amount ?? 0)?.toLocaleString()}`}</p>
    </div>
  );
}
