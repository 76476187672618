import { FC } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RequireAuth } from "./utils/RequireAuth";
import "./App.css";
import {
  AccountVerification,
  Bookings,
  Business,
  BusinessBookings,
  BusinessDrivers,
  Chats,
  CreateRole,
  CreateStaff,
  Customers,
  Drivers,
  ForgotPassword,
  Home,
  Login,
  NewsLetter,
  OrderTracker,
  Pricing,
  Requests,
  ResetPassword,
  Roles,
  Setting,
  SmtpSettings,
  Staffs,
  TrackBooking,
  Transactions,
} from "./pages";
import { ToastContextProvider } from "./utils/toastContainer";
import AccountDeletion from "./components/account-deletion/AccountDeletion";
import { AdminPermissionProvider } from "./context/AdminPermission";

const App: FC = () => {
  return (
    <div className="App w-full h-full text-[#221662] text-[13px] sm:text-sm">
      <Router>
        <ToastContextProvider>
          <AdminPermissionProvider>
            <Routes>
              <Route path="/delete-account" element={<AccountDeletion />} />
              <Route path="/" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/dashboard"
                element={
                  <RequireAuth>
                    <Home />
                  </RequireAuth>
                }
              />
              <Route
                path="/newsletters"
                element={
                  <RequireAuth>
                    <NewsLetter />
                  </RequireAuth>
                }
              />
              <Route
                path="/settings/smtp-settings"
                element={
                  <RequireAuth>
                    <SmtpSettings />
                  </RequireAuth>
                }
              />
              <Route
                path="/pricing"
                element={
                  <RequireAuth>
                    <Pricing />
                  </RequireAuth>
                }
              />
              <Route
                path="/staffs"
                element={
                  <RequireAuth>
                    <Staffs />
                  </RequireAuth>
                }
              />

              <Route
                path="/verifying"
                element={
                  <RequireAuth>
                    <AccountVerification />
                  </RequireAuth>
                }
              />

              <Route
                path="/staffs/role"
                element={
                  <RequireAuth>
                    <Roles />
                  </RequireAuth>
                }
              />
              <Route
                path="/staffs/role/create"
                element={
                  <RequireAuth>
                    <CreateRole />
                  </RequireAuth>
                }
              />
              <Route
                path="/staffs/create"
                element={
                  <RequireAuth>
                    <CreateStaff />
                  </RequireAuth>
                }
              />

              <Route
                path="/customers"
                element={
                  <RequireAuth>
                    <Customers />
                  </RequireAuth>
                }
              />
              <Route
                path="/requests"
                element={
                  <RequireAuth>
                    <Requests />
                  </RequireAuth>
                }
              />
              <Route
                path="/drivers"
                element={
                  <RequireAuth>
                    <Drivers />
                  </RequireAuth>
                }
              />
              <Route
                path="/transactions"
                element={
                  <RequireAuth>
                    <Transactions />
                  </RequireAuth>
                }
              />
              <Route
                path="/business"
                element={
                  <RequireAuth>
                    <Business />
                  </RequireAuth>
                }
              />
              <Route
                path="/bookings"
                element={
                  <RequireAuth>
                    <Bookings />
                  </RequireAuth>
                }
              />
              <Route
                path="/business/drivers/:id"
                element={
                  <RequireAuth>
                    <BusinessDrivers />
                  </RequireAuth>
                }
              />
              <Route
                path="/business/bookings/:id"
                element={
                  <RequireAuth>
                    <BusinessBookings />
                  </RequireAuth>
                }
              />
              <Route
                path="/settings"
                element={
                  <RequireAuth>
                    <Setting />
                  </RequireAuth>
                }
              />
              <Route
                path="/chat"
                element={
                  <RequireAuth>
                    <Chats />
                  </RequireAuth>
                }
              />
              <Route
                path="/track-booking"
                element={
                  <RequireAuth>
                    <TrackBooking />
                  </RequireAuth>
                }
              />
              <Route path="/order-tracker" element={<OrderTracker />} />
            </Routes>
          </AdminPermissionProvider>
        </ToastContextProvider>
      </Router>
    </div>
  );
};

export default App;
