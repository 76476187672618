import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { LiaUserCircleSolid } from "react-icons/lia";
import { IoAttach } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiSolidNavigation } from "react-icons/bi";
import { MdNavigateBefore } from "react-icons/md";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import { singleDispatchChat, singleUserChat } from "../../../../utils/api";
import { errorToast } from "../../../../utils/errorToast";
import { useToast } from "../../../../utils/toastContainer";
import { formatDate } from "../../../../utils/formatDate";
import { getSingleImageUrl } from "../../../../utils/getImageUrl";

const socket = io("https://dashxtest-b9d03e7f15a6.herokuapp.com");

export function CurrentChat({
  islist,
  name,
  setList,
  currentChatId,
  active,
}: {
  islist: boolean;
  currentChatId: string;
  active: string;
  name: string;
  setList: Dispatch<SetStateAction<boolean>>;
}) {
  const { toast } = useToast();
  const { currentUser, token } = useSelector((state: any) => state.user);
  const [data, setData] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const messagesRef = useRef<HTMLDivElement | null>(null);

  socket.on("connect", () => {
    console.log("Connected to server");
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from server");
  });

  useEffect(() => {
    if (currentChatId) {
      if (active === "drivers") {
        // Listen for messages from dispatch
        socket.on("receieve_dispatch_support", (message) => {
          console.log({ message });
          setData((prevMessages: any[]) => [...prevMessages, message]);
        });
      } else {
        // Listen for messages from user
        socket.on("receieve_user_support", (message) => {
          console.log({ message });
          setData((prevMessages: any[]) => [...prevMessages, message]);
        });
      }
    }

    // Clean up listeners when the component is unmounted
    return () => {
      socket.off("receieve_dispatch_support");
      socket.off("receieve_user_support");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChatId, active]);
  async function sendMessage(type: string, image?: string) {
    setSending(true);
    const payload =
      active === "drivers"
        ? {
            userid: currentChatId,
            type,
            usertype: "admin",
            text: message || image,
          }
        : {
            userid: currentChatId,
            type,
            usertype: "admin",
            text: message || image,
          };
    if (active === "drivers") {
      socket.emit("send_dispatch_support", payload);
    } else {
      socket.emit("send_user_support", payload);
    }

    setSending(false);
    setMessage("");
    fetchData();
  }

  useEffect(() => {
    if (currentChatId) {
      socket.emit("supportuser", { userid: currentChatId }, (message: any) =>
        console.log({ message })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChatId]);

  async function handleSubmit(e: any) {
    if (e.target.files[0]) {
      await getSingleImageUrl(e.target.files[0]).then((res) => {
        sendMessage("image", res);
      });
    }
  }

  async function fetchData() {
    if (currentChatId) {
      const payload =
        active === "drivers"
          ? {
              adminid: currentUser?.id || currentUser?._id,
              dispatchid: currentChatId,
            }
          : {
              adminid: currentUser?.id || currentUser?._id,
              userid: currentChatId,
            };
      let queryFunction =
        active === "drivers" ? singleDispatchChat : singleUserChat;
      await queryFunction(payload, token)
        .then((res) => {
          const { data } = res?.data;
          // console.log(res?.data)
          setData(data);
        })
        .catch((err) => {
          errorToast(err, toast);
        });
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChatId]);

  useEffect(() => {
    const currentRef = messagesRef.current;
    const handleDOMNodeInserted = (event: Event) => {
      if (currentRef) {
        const currentTarget = event.currentTarget as HTMLDivElement | null;
        currentTarget?.scroll({
          top: currentTarget?.scrollHeight,
          behavior: "smooth",
        });
      }
    };
    if (currentRef) {
      currentRef?.addEventListener("DOMNodeInserted", handleDOMNodeInserted);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      if (currentRef) {
        currentRef?.removeEventListener(
          "DOMNodeInserted",
          handleDOMNodeInserted
        );
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesRef?.current]);
  return (
    <div
      className={` relative max-[880px]:w-full w-full rounded-lg h-full bg-white p-1 sm:p-4 ${
        islist ? "block w-full" : "max-[880px]:hidden"
      }`}
    >
      <div className="w-full mb-4  justify-between items-center flex">
        <div className="flex space-x-2 items-center">
          <button
            onClick={() => {
              setList((prev) => !prev);
            }}
          >
            <MdNavigateBefore className="text-2xl" />
          </button>
          <div className="flex items-center gap-x-2">
            <LiaUserCircleSolid className="text-4xl text-dashx" />
            <p>{name}</p>
          </div>
        </div>

        <div className=" flex items-center">
          <button className="p-2 rounded-full  bg-gray-100">
            <BsThreeDotsVertical className="text-xl" />
          </button>
        </div>
      </div>

      <div
        ref={messagesRef}
        className=" relative h-[90%] pt-4 pb-20 overflow-y-auto no-scrollbar w-full"
      >
        {data?.length === 0 && (
          <div className="flex  items-center justify-center w-full h-[300px]">
            <p className="text-red-600">- Chat is Empty -</p>
          </div>
        )}
        {Array.isArray(data) &&
          data?.map(({ usertype, text, type, createdAt }, idx) => (
            <div key={idx} className="flex flex-col">
              {usertype !== "admin" && (
                <Sender
                  text={text}
                  type={type}
                  name={name}
                  createdAt={createdAt}
                />
              )}

              {usertype === "admin" && (
                <Receiver text={text} type={type} createdAt={createdAt} />
              )}
            </div>
          ))}
      </div>
      <div className="bottom-0 absolute  w-full inset-x-0 p-2 bg-white sm:p-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage("text");
          }}
          className="w-full relative h-12 "
        >
          <label
            htmlFor="upload"
            className="absolute bg-gray-300  rounded-full h-10 w-10 items-center justify-center flex p-1 top-1 left-2"
          >
            <IoAttach className="text-gray-50 transform rotate-45 text-[22px]" />
            <input
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit(e);
              }}
              id="upload"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              hidden
              className="w-full h-full"
            />
          </label>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="border h-full border-gray-100  placeholder:text-gray-400 bg-gray-200 rounded-md focus:outline-none w-full pr-8 pl-16"
            placeholder="Type a message..."
          />
          <button
            type="submit"
            disabled={message === "" || sending}
            className="absolute top-1 right-2 bg-dashx rounded-full h-9 w-9 flex items-center justify-center p-1"
          >
            <BiSolidNavigation className="text-white text-[22px]" />
          </button>
        </form>
      </div>
    </div>
  );
}

function Sender({
  text,
  type,
  name,
  createdAt,
}: {
  name: string;
  text: string;
  type: string;
  createdAt: string;
}) {
  const formattedTime = useMemo(() => formatDate(createdAt), [createdAt]);
  return (
    <div className="w-full h-full">
      <div className="w-[70%] mb-3 float-left grid grid-cols-9 gap-3">
        <div className="w-full col-span-9 flex flex-col items-start justify-start gap-y-2">
          <div className="flex items-center gap-x-4">
            <p className="text-[#221662]">{name}</p>
            <p className="text-xs sm:text-[13px]">{`${formattedTime?.date} ${formattedTime?.time}`}</p>
          </div>
          <div className="   text-white  h-fit bg-dashx rounded-t-lg rounded-br-lg px-4 py-2">
            {type === "text" ? (
              <div className="font-medium text-[13px] sm:text-sm w-full justify-start items-start flex fle-wrap">
                {text}
              </div>
            ) : (
              <div className="w-full h-[250px] rounded-lg">
                <img
                  src={text}
                  alt="alt"
                  className="w-full h-full object-contain rounded-lg"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function Receiver({
  text,
  type,
  createdAt,
}: {
  text: string;
  type: string;
  createdAt: string;
}) {
  const formattedTime = useMemo(() => formatDate(createdAt), [createdAt]);
  return (
    <div className="w-full h-full">
      <div className=" float-right  mb-3 w-[70%] grid grid-cols-9 gap-0 sm:gap-3">
        <div className="col-span-9 w-full flex flex-col items-end justify-end gap-y-2">
          <div className="flex items-center gap-x-4">
            <p className="text-xs sm:text-[13px]">{`${formattedTime?.date} ${formattedTime?.time}`}</p>
            <p className="text-[#221662]">Admin</p>
          </div>
          <div className=" rounded-t-lg   h-fit bg-purple-100 rounded-bl-lg px-4 py-2">
            {type === "text" ? (
              <div className="font-medium text-[13px] sm:text-sm w-full justify-start items-start flex fle-wrap">
                {text}
              </div>
            ) : (
              <div className="w-full h-[250px] rounded-lg">
                <img
                  src={text}
                  alt="alt"
                  className="w-full h-full object-contain rounded-lg"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
