import { useForm } from "react-hook-form";
import smart from "../../assets/png/logo.png";
import { Button, InputField } from "../composables";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Loader2Icon } from "lucide-react";
import { useToast } from "../../utils/toastContainer";
import { MdCheck } from "react-icons/md";

const schema = z.object({
  email: z
    .string()
    .email({ message: "Email must be a valid email" })
    .min(1, { message: "Email is required" }),
  reason: z.string().min(3, { message: "State your reason" }),
  name: z.string().min(3, { message: "Name is requried" }),
});

export default function AccountDeletion() {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  async function onSubmit(values: z.infer<typeof schema>) {
    setLoading(true);
    toast({
      message: (
        <div className="flex items-center gap-x-2">
          <MdCheck className="text-green-700 text-[22px]" />
          <p>Request Sent Successfully. </p>
        </div>
      ),
      className: "bg-green-200",
    });
    setLoading(false);
  }
  return (
    <div className="w-full max-w-[600px] m-auto flex h-full flex-col gap-y-3 items-center justify-center">
      <div className="w-[100px] bg-dashx p-1 h-[60px]">
        <img src={smart} alt="" className="w-full h-full object-contain" />
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex items-start justify-start gap-y-3 flex-col"
      >
        <h2 className="font-semibold text-2xl mb-1">Account Deletion Form</h2>

        <InputField
          placeholder="ola"
          {...register("name")}
          hasLabel
          label="Name"
          type="text"
          error={errors.name?.message}
        />

        <InputField
          placeholder="ola@example.com"
          {...register("email")}
          hasLabel
          label="Email Address"
          type="email"
          error={errors.email?.message}
        />

        <div className="flex flex-col w-full items-start justify-start gap-y-3">
          <p className="">State your reason</p>
          <textarea
            {...register("reason")}
            placeholder="Tell us why u want to delete your account"
            className="w-full resize-none rounded-md border border-slate-400  p-3 text-sm ring-offset-zinc-300 file:border-0  file:text-sm file:font-medium placeholder:text-gray-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-48"
          ></textarea>
        </div>

        <Button
          type="submit"
          className="self-end w-56 gap-x-2 bg-dashx text-white"
        >
          {loading && <Loader2Icon className="text-[22px]" />}
          Delete Account
        </Button>
      </form>
    </div>
  );
}
