import { Container } from "../../container/Container.component";
import { Button, InputField, Switch } from "../../composables";
import { useEffect, useState } from "react";
import { rolePermissions } from "./_components/utils";
import { useForm } from "react-hook-form";
import { createRole, updateRole } from "../../../utils/api";
import { useSelector } from "react-redux";
import { useToast } from "../../../utils/toastContainer";
import { MdCheck } from "react-icons/md";
import { errorToast } from "../../../utils/errorToast";
import { Loader2Icon } from "lucide-react";
import { useLocation } from "react-router-dom";

type TPermission = {
  title: string;
  role: { status: boolean; name: string }[];
};

type FormValue = {
  role: string;
};
export default function CreateRole() {
  const { currentUser, token } = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { toast } = useToast();
  const [permissions, setPermissions] =
    useState<TPermission[]>(rolePermissions);
  const form = useForm<FormValue>({});
  const {
    formState: { errors },
  } = form;
  function handleChange(title: string, name: string) {
    const updated = permissions?.map((value) => {
      if (value.title === title) {
        return {
          ...value,
          role: value?.role?.map((val) => {
            if (val.name === name) {
              return { ...val, status: !val.status };
            }
            return val;
          }),
        };
      }
      return value;
    });

    setPermissions(updated);
  }

  useEffect(() => {
    if (state?.role) {
    
      const { role } = state;
      form.reset({
        role: role?.role,
      });
      //add the whitespace
      let perm = role?.permissions?.map((obj: any) => {
        const [name, status] = Object.entries(obj)[0];
        return { name: name.replace(/([a-z])([A-Z])/g, "$1 $2"), status };
      });

      // match roles
      const updatedPermissions = rolePermissions.map((permission) => {
        const updatedRole = permission.role.map((role) => {
          const matchingRole = perm.find((r: any) => r?.name === role.name);

          return matchingRole?.name ? matchingRole : role;
        });

        return { ...permission, role: updatedRole };
      });

      setPermissions(updatedPermissions);
    }
    /*
//data.map((obj) => {
const [name, status] = Object.entries(obj)[0];
return {name: name.replace(/([a-z])([A-Z])/g, '$1 $2'), status}
})
const updatedPermissions = rolePermissions.map(permission => {
  const updatedRole = permission.role.map(role => {
    const matchingRole = roles.find(r => r === role.name);
    return matchingRole ? { status: true, name: matchingRole } : role;
  });
  
  return { ...permission, role: updatedRole };
});


*/
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.role]);

  async function onSubmit(values: FormValue) {
    const perm = permissions.map((value) => value.role).flat();
    const payload = state?.role?._id
      ? {
          ...values,
          permissions: perm.map(({ name, status }) => {
            return {
              [name.replace(/\s/g, "")]: status,
            };
          }),
          adminid: currentUser?._id || currentUser?.id,
          roleid: state?.role?._id,
        }
      : {
          ...values,
          permissions: perm.map(({ name, status }) => {
            return {
              [name.replace(/\s/g, "")]: status,
            };
          }),
          adminid: currentUser?._id || currentUser?.id,
        };
    setLoading(true);

    const asyncQuery = state?.role?._id ? updateRole : createRole;
    await asyncQuery(payload, token)
      .then((res) => {
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>
                {state?.role?._id
                  ? "Role Updated Successfully"
                  : "Role Created Successfully"}
              </p>
            </div>
          ),
          className: "bg-green-100",
        });
      })
      .catch((err) => {
        errorToast(err, toast);
      })
      .finally(() => {
        setLoading(false);
      });

    // text.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  return (
    <Container>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-full border rounded-md pb-4 flex mx-auto flex-col  gap-y-3 items-start justify-start"
      >
        <h3 className="w-full mb-3 text-start border-b p-4 font-semibold ">
          Create Staff
        </h3>

        <div className="w-full mx-auto max-w-[650px] px-4">
          <InputField
            label="Role Name"
            {...form.register("role")}
            type="text"
            hasLabel
            placeholder="Role"
            error={errors?.role?.message}
          />
        </div>

        <h3 className="w-full mb-3 text-start border-b p-4 font-semibold ">
          Permissions
        </h3>

        <div className="w-full p-3">
          {permissions.map(({ title, role }) => (
            <div key={title} className="w-full mb-4  pb-4 rounded-lg border">
              <h3 className="w-full mb-3 text-start border-b p-4 bg-gray-100 font-semibold ">
                {title}
              </h3>
              <div className="flex p-2 w-full flex-wrap items-start justify-start gap-3">
                {role.map(({ status, name }) => (
                  <div className="w-[200px] p-2 flex flex-col items-start justify-start gap-y-2 rounded-md border border-gray-300">
                    <p>{name}</p>
                    <Switch
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleChange(title, name);
                      }}
                      checked={status}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <Button
          type="submit"
          className="mx-4 gap-x-2 self-end w-[130px] text-gray-50 bg-dashx"
        >
          {loading && <Loader2Icon className="animate-spin" />}
          <p> Submit</p>
        </Button>
      </form>
    </Container>
  );
}
