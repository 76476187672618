import { Container } from "../container/Container.component";
import { InputField, Button, TextEditor } from "../composables";
import { UseFormReturn, useForm } from "react-hook-form";
import { useEffect, useState, useMemo } from "react";
import {  MdNavigateNext } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import { cn } from "../../lib";
import { useGetQueries } from "../../hooks";
import { sendNewsLetter, retrieveBusiness, retrieveDispatch, retrieveUsers } from "../../utils/api";
 import { useToast } from "../../utils/toastContainer";
 import { errorToast } from "../../utils/errorToast";
 import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { MdCheck } from "react-icons/md";
import * as z from "zod";
import { newsLetterSchema } from "../../validations";

export default function NewsLetter() {
  const { currentUser, token } = useSelector((state: any) => state.user);
 const {data: users} = useGetQueries({adminid: currentUser?._id || currentUser?.id},retrieveUsers )
 const {data: business} = useGetQueries({adminid: currentUser?._id || currentUser?.id},retrieveBusiness )
 const {data: riders} = useGetQueries({adminid: currentUser?._id || currentUser?.id},retrieveDispatch )
  const [loading, setLoading ] = useState(false);
 const [recipients, setRecipients] = useState<string[]>(["Users"]);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof newsLetterSchema>>({
    resolver: zodResolver(newsLetterSchema),
  });

  const {
    formState: { errors },
  } = form;


  const customers = useMemo(() => {
    if (Array.isArray(users)) {
      return users?.map(
        ({ email, _id }: { _id: string; email: string }) => {
          return {
            id: _id,
            email,
          };
        }
      );
    } else {
      return [];
    }
  }, [users]);

  const drivers = useMemo(() => {
    if (Array.isArray(riders)) {
      return riders?.map(
        ({ email, _id }: { _id: string; email: string }) => {
          return {
            id: _id,
            email,
          };
        }
      );
    } else {
      return [];
    }
  }, [riders]);

  const businessOwners = useMemo(() => {
    if (Array.isArray(business)) {
      return business?.map(
        ({ email, _id }: { _id: string; email: string }) => {
          return {
            id: _id,
            email,
          };
        }
      );
    } else {
      return [];
    }
  }, [business]);

  async function onSubmit(values: z.infer<typeof newsLetterSchema>) {

     if (values?.emails === undefined || values?.emails?.length === 0) {
      return toast({
        message: (
          <div className="flex items-center gap-x-2">
            <MdCheck className="text-red-700 text-[22px]" />
            <p>Pls Add Emails</p>
          </div>
        ),
        className: "bg-white",
      });
    }

    setLoading(true);
    await sendNewsLetter(
      { 
        ...values,
          content:`<div>${values?.content}</div>`,
         adminid: currentUser?._id || currentUser?.id },
      token
    )
      .then((res) => {
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>Mail Sent Successfully</p>
            </div>
          ),
          className: "bg-green-100",
        });
        form.reset({
          subject: "",
          content: "",
        });
      })
      .catch((err) => {
        errorToast(err, toast);
      })
      .finally(() => {
        setLoading(false);
        window.location.reload();
      });
    
  }

  // content
  function handleChange(value: any) {
    form.setValue("content", value);
  }

  return (
    <Container>
      <div className="w-full px-2 sm:px-8">
        <div className="w-full max-w-3xl mx-auto border rounded-md">
          <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-center justify-start sm:justify-normal border-b sm:gap-x-8  p-4 w-full">
            <h2 className="text-lg font-semibold">Send NewsLetter</h2>
            
            <div className="w-full sm:w-[60%] flex items-center gap-x-6">
              {["Business", "Users", "Riders"].map((value) => (
                <label className="flex items-center gap-x-2">
                  <input
                    type="checkbox"
                    checked={recipients?.includes(value)}
                    onChange={() => {
                      if (recipients?.includes(value)) {
                       
                        setRecipients((prev) =>
                          prev?.filter((v) => v !== value)
                        );
                      } else {
                        setRecipients((prev) => [...prev, value]);
                      }
                    }}
                    // value={value}
                    className="accent-dashx h-4 w-4 rounded-md"
                  />
                  <span>{value}</span>
                </label>
              ))}
            </div>
          </div>

          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full p-4 flex flex-col gap-y-4 items-start justify-start"
          >
       
              <>
              {recipients.includes("Business") &&  <div className="w-full grid grid-cols-1 gap-2 ">
                  <label className={"lg:col-span-2 font-medium"}>
                    Email (Business only)
                  </label>

                  <div className=" w-full">
                    <UserMailsDropDown
                      users={businessOwners}
                      form={form}
                    />
                  </div>
                </div>}
             {recipients.includes("Users") &&   <div className="w-full grid grid-cols-1 gap-2 ">
                  <label className={"lg:col-span-2 font-medium"}>
                    Email (Users only)
                  </label>

                  <div className=" w-full">
                    <UserMailsDropDown
                      users={customers}
                      form={form}
                    />
                  </div>
                </div>}

              {recipients.includes("Riders") &&  <div className="w-full grid grid-cols-1 gap-2 ">
                  <label className={"lg:col-span-2 font-medium"}>
                    Email (Riders only)
                  </label>

                  <div className="w-full">
                    <UserMailsDropDown
                      users={drivers}
                      form={form}
                    />
                  </div>
                </div>}
              </>
      

            <InputField
              label="NewsLetter Subject"
              {...form.register("subject")}
              type="text"
              hasLabel
              placeholder="Newsletter Subject"
              error={errors.subject?.message}
            />

            <div className="w-full grid grid-cols-1 gap-2 ">
              <label
                className={cn(
                  " font-medium ",
                  errors.content?.message && "text-red-500"
                )}
              >
                NewsLetter Content
              </label>

              <div className=" w-full">
                <TextEditor onChange={handleChange} />

                {errors.content?.message && (
                  <p className="text-red-500 text-xs">
                    {errors.content?.message}
                  </p>
                )}
              </div>
            </div>

            <Button
              disabled={loading}
              className="w-[200px] gap-x-2 self-end text-gray-50 font-medium bg-dashx"
            >
              {loading && <Loader2Icon className="animate-spin" />}
              <p> Send NewsLetter</p>
            </Button>
          </form>
        </div>
      </div>
    </Container>
  );
}

function UserMailsDropDown({
  form,
  users,
}: {
  users: { id: string; email: string }[];
  form: UseFormReturn<any, any, any>;
}) {
  const [value, setValue] = useState<{ id: string; email: string }[]>([]);
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    if (value) {
      form.setValue(
        "emails",
        value?.map((v) => v?.email)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="w-full md:col-span-5 relative">
      <div className="w-full relative h-12">
        <div
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShow((prev) => !prev);
          }}
          //readOnly={value !== "" &&  allData?.length }
          className={cn(
            "w-full h-12 px-4 flex items-center justify-start overflow-hidden rounded-lg border border-gray-300",
            value?.length === 0 && "text-gray-300"
          )}
        >
          {value?.map((v) => v?.email)?.toString() || "Select an Option"}
        </div>

        <MdNavigateNext className="text-[22px] rotate-90 absolute right-3 text-xl top-3" />
      </div>
      {isShow && (
        <div className="w-full absolute top-[53px]">
          <span
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShow((prev) => !prev);
            }}
            className="w-full h-full inset-0 fixed z-[999]"
          ></span>
          <div className="rounded-md flex z-[99999] flex-col overflow-y-auto shadow items-start justify-start bg-white relative w-full h-fit max-h-64">
            <div className="w-full flex items-center justify-around py-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setValue([]);
                }}
                className="text-[10px] sm:text-sm px-6 sm:px-10 py-2 rounded-md bg-gray-200"
              >
                DESELECT ALL
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setValue(users?.map((item) => item));
                }}
                className="bg-dashx/30 text-[10px] sm:text-sm px-6 sm:px-10 py-2 rounded-md"
              >
                SELECT ALL
              </button>
            </div>
            {Array.isArray(users) &&
              users?.map((item, index) => {
                return (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (value.includes(item)) {
                        setValue(value?.filter((v) => v?.id !== item?.id));
                      } else {
                        setValue((prev) => [...prev, item]);
                      }
                    }}
                    key={index}
                    className="w-full flex items-start  justify-between p-3 hover:bg-dashx/30"
                  >
                    <p>{item?.email || "select an option"}</p>
                    {value.includes(item) && (
                      <IoMdCheckmark className="text-[22px]" />
                    )}
                  </button>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
