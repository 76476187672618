import { InputHTMLAttributes, forwardRef, useState } from "react";
import { TogglePasswordVisibilty } from "../togglePasswordVisibility/TogglePasswordVisibility.component";
import { cn } from "../../../lib";

// create a customized input component
type PasswordFieldParams = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  label?: string;
  hasLabel?: boolean;
  className?: string;
};

function ErrorText({ children }: { children?: string }) {
  return <>{children && <p className="text-red-500 pt-1 ">{children}</p>}</>;
}

//This is a function component but we need it to act as an input element
// using react fowardRef because react-hook-form need access to the ref of the input element
export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldParams>(
  function PasswordComp(
    { error, label, hasLabel, className, ...inputParams },
    ref
  ) {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className="w-full h-10 sm:h-12 relative">
        {hasLabel && (
          <label
            htmlFor={showPassword ? "text" : "password"}
            className={cn("mb-3 font-medium", error && "text-red-500")}
          >
            {label}
          </label>
        )}
        <input
          ref={ref}
          {...inputParams}
          type={showPassword ? "text" : "password"}
          className={cn(
            "flex h-11 w-full rounded-md border border-slate-400 px-3 py-2 text-sm  file:border-0  file:text-sm file:font-medium placeholder:text-gray-300 focus-visible:outline-none  disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
        />
        <TogglePasswordVisibilty
          isVisible={showPassword}
          toggleVisibility={() => {
            setShowPassword((showPassword) => !showPassword);
          }}
        />
        <ErrorText>{error}</ErrorText>
      </div>
    );
  }
);
