import { Loader2Icon } from "lucide-react";
import { Container } from "../container/Container.component";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useNavLinks } from "../../hooks";

export default function AccountVerification() {
  const { reformedNavLink } = useNavLinks();
  const navigate = useNavigate();

  // console.log(navLinks, loading)
  useEffect(() => {
    if (reformedNavLink && reformedNavLink?.length > 0) {
      //  console.log("yeah")
      const firstElement = reformedNavLink?.find((_, index) => index === 0);
      if (firstElement && firstElement.hasOwnProperty("tab")) {
        if (
          firstElement &&
          firstElement?.tab &&
          firstElement?.tab?.length > 0
        ) {
          const firstTab = firstElement?.tab.find((_, index) => index === 0);
          if (firstTab?.link) navigate(firstTab?.link);
        }
      } else if (firstElement) {
        if (firstElement?.link) navigate(firstElement?.link);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reformedNavLink]);



  return (
    <Container>
      <div className="w-full inset-0 h-full z-[900] fixed backdrop-blur">
        <div className="w-fit h-fit m-auto inset-0 absolute flex flex-col items-center justify-center">
          <Loader2Icon size={32} className="animate-spin text-dashx" />
          <p className="font-medium">Verifying Account ...</p>
        </div>
      </div>
    </Container>
  );
}
