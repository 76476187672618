export const formatNumber = (number:number) => {
    const suffixes = ["", "k", "M", "B", "T"];
    
    if (isNaN(number)) {
      return "Invalid Number";
    }
    
    const suffixNum = Math.floor((Math.abs(number).toString().length - 1) / 3);
    
    let shortValue: any = parseFloat(
      (suffixNum !== 0 ? number / Math.pow(1000, suffixNum) : number).toPrecision(3)
    );
  
    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1);
    }

    const formattedValue = parseFloat(shortValue).toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
  
    return (number < 0 ? '-' : '') + formattedValue + suffixes[suffixNum];
  };
  