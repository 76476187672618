import { Container } from "../container/Container.component";
import { CustomerWidget } from "./_component";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { retrieveUsers } from "../../utils/api";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";
import { checkPermission } from "../../hooks";
import { cn } from "../../lib";

export default function Customers() {
  const { currentUser } = useSelector((state: any) => state.user);
  const status = checkPermission("SetUserStatus")
  const { data, loading } = useGetQueries(
    { adminid: currentUser?.id || currentUser?._id },
    retrieveUsers
  );

  return (
    <Container>
      <h2 className="font-semibold text-base sm:text-xl mb-6">
        Users List
      </h2>

      <div className="dashboard-scroll-style w-full h-fit pb-3 overflow-x-auto">
        <div className="w-full min-w-[1000px] flex flex-col gap-y-3">
          {/*
          <div className="w-full px-2 border-b py-3 flex items-center gap-x-2">
            <div className="relative w-[300px] rounded-xl h-11">
              <InputField
                placeholder="search"
                type="search"
                className="w-full h-11 pl-10 border-gray-300"
              />
              <IoIosSearch className="text-[22px] text-gray-300 absolute top-3 left-3 " />
            </div>
            {
              <SelectField
                name="status"
                placeHolder="Status"
                options={status}
                width="150px"
                onChanged={(v)=> null}
                control={control}
              />
            }
            <InputField
              placeholder="date"
              type="date"
              className="h-11 w-[150px] border-gray-300"
            />
          </div>
          */}

          <div className={cn("w-full grid p-2 font-semibold grid-cols-6 gap-4 items-center", status && "grid-cols-7")}>
            <p>ID</p>
            <p className="w-full col-span-2">Users</p>
            <p>Mobile No</p>
            <p>Email</p>
        
           {status && <p>Status</p>}
            <p>Actions</p>
          </div>
          {loading && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <Loader2Icon size={48} className=" animate-spin text-gray-600" />
            </div>
          )}
          {!loading && data?.length === 0 && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <p className="text-red-600">- No Data -</p>
            </div>
          )}
          {Array.isArray(data) && data?.map(({name, _id,email,phone,photo, user_blocked }, idx) => (
            <CustomerWidget key={email} serialNo={idx} id={_id} photo={photo} name={name} email={email} phone={phone} user_blocked={user_blocked}/>
          ))}
        </div>
      </div>
    </Container>
  );
}
