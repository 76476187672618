import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userProfile, retrieveRole } from "../utils/api";
import { useEffect, useState } from "react";
import { useGetQueries } from "./getQueries.hook";
import { useReformedNavLink } from "../context/AdminPermission";
import { navLinks } from "../constant/constant";
import _ from "lodash";
import { rolePermissions } from "../components/staffs/roles/_components/utils";

interface RoleType {
  status: boolean | any;
  name: string;
}

interface RolePermissionType {
  title: string;
  role: RoleType[];
}

export function useGetUser() {
  const navigate = useNavigate();
  const { token, currentUser } = useSelector((state: any) => state.user);

  const { data: roles, loading } = useGetQueries({adminid: currentUser?._id || currentUser?.id},retrieveRole);
 

  useEffect(() => {
    (async () => {
      if (!token) {
        navigate("/");
        return;
      }
      await userProfile(
        { adminid: currentUser?.id || currentUser?._id },
        token
      )
        .then((res) => {
          const { data } = res?.data;
          if (Array.isArray(roles) && roles?.length > 0) {
            const permissions = roles?.find(
              (r: any) => r?._id === data?.roles[0]
            )?.permissions;
           
            localStorage.setItem(
              "user",
              JSON.stringify({ ...data, permissions })
            );
          }

          return data;
        })
        .catch((err) => {
          const errorMessage = err.response.data.message;
          if (
            errorMessage === "invalid ID type" ||
            errorMessage === "invalid token" ||
            errorMessage === "admin does not exist"
          ) {
            navigate("/");
          }
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?._id, currentUser?.id, token, roles]);

  return {
    loading,
  
  };
}


export function checkPermission(type: string) {
  const user = localStorage.getItem("user");
 let status = true

  if (user) {
    const permissions = JSON.parse(user)?.permissions;

    const newStatus = permissions?.find((value: any) =>
      value.hasOwnProperty(type)
    );

   status = newStatus[type]

 //  console.log(permissions)
  }

  return status
}

export function useGetUserAndRoles() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const { token, currentUser } = useSelector((state: any) => state.user);

  useEffect(() => {
    (async () => {
      if (!token) {
        navigate("/");
        return;
      }
      try {
        setLoading(true);
        const [roles, user] = await Promise.all([
          retrieveRole(
            { adminid: currentUser?.id || currentUser?._id },
            token
          ),
          userProfile({ adminid: currentUser?.id || currentUser?._id }, token),
        ]);

        //  const { data } = res?.data;
        setLoading(false);
        const profile = user?.data?.data;
        const allRoles = roles?.data?.data;
        if (Array.isArray(allRoles) && allRoles?.length > 0) {
          const permissions = allRoles?.find(
            (r: any) => r?._id === profile?.roles[0]
          )?.permissions;
          //  console.log({permissions})
          setUserData({ ...profile, permissions });
        }
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?._id, currentUser?.id, token]);

  return {
    loading,
    userData,
  };
}

export function useNavLinks() {
  const { reformedNavLink, setReformedNavLink } = useReformedNavLink();
  const { loading, userData } = useGetUserAndRoles();

  useEffect(() => {
    (async () => {
      if (userData && !loading) {
        const permissions = userData?.permissions;
        if (Array.isArray(permissions)) {
          const updatedPermissions = rolePermissions.map((permission) => {
            const updatedRole = permission.role.map((role) => {
              const matchingRole = permissions.find((r: any) => {
                const [name] = Object.entries(r)[0];
                return name === role.name?.replace(/\s/g, "");
              });

              return matchingRole;
            });
            const restructure = updatedRole?.map((value: any) => {
              const [name, status] = Object.entries(value)[0];

              return { status, name: name.replace(/([a-z])([A-Z])/g, "$1 $2") };
            });
            return { ...permission, role: restructure };
          });

          //  console.log({ updatedPermissions });

          // Filter out role permissions that do not contain "Show" in their names
          const filteredRoles = updatedPermissions.reduce<RolePermissionType[]>(
            (acc, permission) => {
              const rolesWithShow = permission.role.filter((role) =>
                role.name.toLowerCase().includes("show")
              );
              if (rolesWithShow.length > 0) {
                acc.push({ title: permission.title, role: rolesWithShow });
              }
              return acc;
            },
            []
          );

          // Ensure unique roles
          const uniqueFilteredRoles: RolePermissionType[] = Array.from(
            new Set(filteredRoles.map((role) => JSON.stringify(role)))
          ).map((stringified) => JSON.parse(stringified));

          //  console.log(uniqueFilteredRoles);
          // Use the filtered role permissions to filter the navLinks

          const filteredNavLinks: any[] = navLinks
            .map((navLink) => {
              if (navLink.tab) {
                const filteredTabs = navLink.tab
                  .map((tab, index) => {
                    const rolePermission = uniqueFilteredRoles.find(
                      (permission) => permission.title === navLink.name
                    );

                    if (rolePermission) {
                      const activeStatus = rolePermission?.role?.map(
                        (
                          role: { status: boolean; name: string },
                          id: number
                        ) => {
                          if (
                            navLink?.tab !== undefined &&
                            id < navLink?.tab?.length &&
                            navLink?.name !== "Settings"
                          ) {
                            return {
                              ...navLink?.tab[id], // Use navLink.tab[id] instead of tab
                              status: role?.status,
                            };
                          } else if (
                            navLink?.tab !== undefined &&
                            id < navLink?.tab?.length &&
                            navLink?.name === "Settings"
                          ) {
                            return {
                              ...navLink?.tab[index], // Use navLink.tab[id] instead of tab
                              status: role?.status,
                            };
                          } else {
                            return null;
                          }
                        }
                      );

                      return activeStatus?.length > 0 ? activeStatus : null;
                    } else if (
                      navLink.tab &&
                      Array.isArray(navLink.tab) &&
                      navLink?.tab?.length
                    ) {
                      const otherPermissions = uniqueFilteredRoles.find(
                        (permission) => permission.title === tab.name
                      );

                      const activeStatus = otherPermissions?.role?.map(
                        (
                          role: { status: boolean; name: string },
                          id: number
                        ) => {
                          if (
                            navLink?.tab !== undefined &&
                            id < navLink?.tab?.length
                          ) {
                            return {
                              ...navLink?.tab[index], // Use navLink.tab[id] instead of tab
                              status: role?.status,
                            };
                          } else {
                            return null;
                          }
                        }
                      );

                      //  console.log({activeStatus})

                      return activeStatus && activeStatus?.length > 0
                        ? activeStatus
                        : null;

                      //  console.log(activeStatus, otherPermissions)
                    }
                    return null;

                    /**
           return rolePermission
          ? rolePermission.role.some(
              (role: any) => role.status === true
            )
          : false;
          */
                  })
                  .flat()
                  .filter((value) => {
                    //  console.log({value})
                    return (
                      value !== null && value !== undefined && value?.status
                    );
                  });

                //  console.log({ filteredTabs });

                if (filteredTabs.length > 0) {
                  return { ...navLink, tab: _.uniqBy(filteredTabs, "name") };
                } else {
                  return null;
                }
              } else {
                const rolePermission = uniqueFilteredRoles.find(
                  (permission) => permission.title === navLink.name
                );

                // console.log("second", rolePermission)

                //  let ccd=  rolePermission.role.every((role:any) => role.status === true);
                //  console.log({ccd})
                if (
                  rolePermission &&
                  rolePermission.role.some((role: any) => role.status === true)
                ) {
                  return navLink;
                } else {
                  return null;
                }
              }
            })
            .filter(Boolean);

          setReformedNavLink(filteredNavLinks);
          //   console.log({ filteredNavLinks });
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, loading]);

 

  // memoized to avoid re-rendering
  return {
    reformedNavLink,
  };
}