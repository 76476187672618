import { SetStateAction, Dispatch, useEffect } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { BiSolidSearch } from "react-icons/bi";
import { LiaUserCircleSolid } from "react-icons/lia";
import { useForm } from "react-hook-form";
import { Button, SelectSingleField } from "../../../composables";
import { MdMenu } from "react-icons/md";
import io from "socket.io-client";
import { useGetQueries } from "../../../../hooks/getQueries.hook";
import { dispatchChatHistory, userChatHistory } from "../../../../utils/api";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";
import { cn } from "../../../../lib";

const socket = io("https://dashxtest-b9d03e7f15a6.herokuapp.com");

export function ChatList({
  islist,
  setList,
  toggleSideBar,
  active,
  setActive,
  setId,
  setUserName,
  currentChatId
}: {
  islist: boolean;
  setId: (id: string) => void;
  setList: Dispatch<SetStateAction<boolean>>;
  setActive: Dispatch<SetStateAction<string>>;
  setUserName: (name: string) => void;
  active: string;
  toggleSideBar: () => void;
  currentChatId: string;
}) {
  const { control } = useForm();
  const { currentUser } = useSelector((state: any) => state.user);
  const { data, loading, refetch } = useGetQueries(
    { adminid: currentUser?.id || currentUser?._id },
    active === "drivers" ? dispatchChatHistory : userChatHistory
  );

  useEffect(() => {
    if (!loading && active) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  socket.on("connect", () => {
    console.log("Connected to server");
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from server");
  });

  /**
 * 
   useEffect(() => {
    // Listen for messages from dispatch
    socket.on("receieve_dispatch_support", (message) => {
      console.log({ message });
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    // Listen for messages from user
    socket.on("receieve_user_support", (message) => {
      console.log({ message });
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    // Clean up listeners when the component is unmounted
    return () => {
      socket.off("receieve_dispatch_support");
      socket.off("receieve_user_support");
    };
  }, []);
 */


  const type = [
    { value: "drivers", label: "Drivers" },
    { value: "customers", label: "Customers" },
  ];
  return (
    <div
      className={` max-[880px]:w-full h-full w-[40%]  rounded-lg bg-white py-2 sm:py-4 ${
        islist ? "max-[880px]:hidden" : "block"
      }`}
    >
      <div className="flex w-full mb-3  px-2 sm:px-4  justify-between items-center">
        <div className="flex  items-center gap-x-2">
          <Button onClick={toggleSideBar} className="lg:hidden bg-transparent">
            <MdMenu className="text-gray-700 text-2xl" />
          </Button>
         
            <SelectSingleField
              width="120px"
              onChanged={(v) => setActive(v.value)}
              options={type}
              control={control}
              name="chats"
              placeHolder="type"
            />
       
        </div>
        <button>
          <AiFillPlusCircle className="text-dashx text-[22px]" />
        </button>
      </div>
      <div className="mb-3 hidden px-2 sm:px-4 relative w-full h-11">
        <div className="absolute top-3 left-6">
          <BiSolidSearch className="text-xl text-gray-400" />
        </div>
        <input
          type="search"
          className="border border-gray-100 placeholder:text-gray-400  bg-gray-200 rounded-md focus:outline-none w-full h-full pl-10"
          placeholder="Search"
        />
      </div>
      <h2 className="font-semibold px-4 sm:px-6 mb-3 text-base sm:text-xl">
        Chats
      </h2>
      {loading && (
        <div className="flex  items-center col-span-full justify-center w-full h-[250px]">
          <Loader2Icon size={48} className=" animate-spin text-gray-600" />
        </div>
      )}
      {!loading && data?.length === 0 && (
        <div className="flex col-span-full  items-center justify-center w-full h-[250px]">
          <p className="text-red-600">- No Chats History -</p>
        </div>
      )}
      {Array.isArray(data) &&
        data?.map(({ name, email, _id }, idx) => {
          return (
            <div
            role="button"
              onClick={() => {
                setId(_id);
                setList(true);
                setUserName(name);
              }}
              className={cn(`w-full cursor-pointer  px-2 sm:px-4 hover:bg-gray-100`, currentChatId === _id && "bg-dashx hover:bg-dashx hover:bg-opacity-30 bg-opacity-30")}
              key={_id}
            >
              <ChatlistWidget name={name} />
            </div>
          );
        })}
    </div>
  );
}

function ChatlistWidget({ name }: { name: string }) {
  return (
    <div className="flex w-full items-start py-2 border-b justify-between">
      <div className=" flex space-x-2 items-center justify-center">
        <LiaUserCircleSolid className="text-4xl text-dashx" />
        <div className="">
          <p className="font-semibold">{name}</p>
        </div>
      </div>
      <p className="text-gray-400 font-medium text-xs sm:text-sm">{""}</p>
    </div>
  );
}
