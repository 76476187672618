import type { InputHTMLAttributes } from "react";
import { forwardRef } from "react";
import { cn } from "../../../lib";

// create a customized input component
type InputFieldParams = InputHTMLAttributes<HTMLInputElement> & {
  type: string;
  label?: string;
  hasLabel?: boolean;
  error?: string;
  parentClassName?: string;
};

function ErrorText({ children }: { children?: string }) {
  return (
    <div>
      {children && <p className="pt-1 text-xs text-red-500 ">{children}</p>}
    </div>
  );
}
// This is a function component but we need it to act as an input element
// using react fowardRef because react-hook-form need access to the ref of the input element
const InputField = forwardRef<HTMLInputElement, InputFieldParams>(function Comp(
  { type, className, error, parentClassName, label, hasLabel, ...inputParams },
  ref
) {
  return (
    <div
      className={cn(
        "relative flex w-full flex-col items-start justify-start",
        parentClassName
      )}
    >
      {hasLabel && (
        <label htmlFor={type} className={cn("mb-3 font-medium", error && "text-red-500")}>
          {label}
        </label>
      )}
      <input
        ref={ref}
        {...inputParams}
        type={type}
        className={cn(
          "flex h-11 w-full rounded-md border border-slate-400 px-3 py-2 text-sm file:border-0  file:text-sm file:font-medium placeholder:text-gray-300 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
      />

      <ErrorText>{error}</ErrorText>
    </div>
  );
});
InputField.displayName = "InputField";

export { InputField };
