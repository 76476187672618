import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

type CircularChartProp = {
  activeTrips: number;
  onlineDrivers: number;
  offlineDrivers: number;
};

const CircularChart = ({
  activeTrips,
  onlineDrivers,
  offlineDrivers,
}: CircularChartProp) => {
  const series = [activeTrips, onlineDrivers, offlineDrivers];

  const options: ApexOptions = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Active Trips", "Online Drivers", "Offline Drivers"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    legend: {
      position: "bottom",
      offsetX: 0,
      height: 30,
    },
  };

  return (
    <div className="md:col-span-2 w-full h-full border rounded-lg bg-white space-y-3 sm:space-y-4 p-3 sm:p-4">
      <p className="font-semibold text-base sm:text-lg">Driver Live Status</p>
      <Chart
        width={"100%"}
        options={options}
        series={series}
        height="350"
        type="donut"
      />
    </div>
  );
};

export default CircularChart;
