import { MdClose } from "react-icons/md";
import { Button,  } from "../../../composables";
import profile from "../../../../assets/png/profile.png";
import { cn } from "../../../../lib";
import { useGetQueries } from "../../../../hooks/getQueries.hook";
import { retrieveSingleDispatch } from "../../../../utils/api";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { Loader2Icon } from "lucide-react";
import { checkPermission } from "../../../../hooks";

export function DriverDetail({ id, close }: { id: string; close: () => void }) {
  const { currentUser } = useSelector((state: any) => state.user);
  const status = checkPermission("SetDriverStatus")
  const { data, loading } = useGetQueries(
    { adminid: currentUser?.id || currentUser?._id, dispatchid: id },
    retrieveSingleDispatch
  );

  function trimDate(date: string) {
    return date?.split("T")[0];
  }

  const registeredDate = useMemo(
    () => trimDate(data?.userDetails?.createdAt),
    [data?.userDetails?.createdAt]
  );

  function restructureDocs(license:string) {
    return [{name: "Drivers License", image: license}]
  }

  const documents = useMemo(
    () => restructureDocs(data?.userDetails?.vehicle?.driving_license),
    [data?.userDetails?.vehicle?.driving_license]
  );

  return (
    <div
      onClick={close}
      className="w-full h-full fixed z-[200] inset-0 bg-black/50"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-[95%] bg-white sm:w-[500px] md:w-[580px] overflow-y-auto rounded-lg shadow-lg m-auto absolute inset-0 h-[85%] flex flex-col px-3 sm:px-4 py-6"
      >
        <div className="w-full flex items-center mb-10 justify-between">
          <h2 className="text-base sm:text-xl font-semibold">Driver Detail</h2>
          <Button onClick={close} className="w-fit px-2 h-11">
            <MdClose className="text-[22px]" />
          </Button>
        </div>
        {loading && (
          <div className="flex items-center justify-center w-full h-[250px]">
            <Loader2Icon size={48} className=" animate-spin text-gray-600" />
          </div>
        )}
        {!loading && (
          <div className="w-full flex flex-col items-start gap-y-6">
            <div className="flex items-center gap-x-2">
              <div className="w-[150px] h-[150px]">
                <img
                  src={data?.userDetails?.photo ?? profile}
                  alt=""
                  className="w-full h-full rounded-lg object-fill"
                />
              </div>
              <div>
                <p className="font-semibold text-base sm:text-lg">
                  {data?.userDetails?.name ?? ""}
                </p>
                
              </div>
            </div>

            <div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 items-center">
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="Email"
                value={data?.userDetails?.email}
              />
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="Mobile Number"
                value={data?.userDetails?.phone}
              />
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="Total Spendings"
                value={`${(data?.sum ?? 0).toLocaleString()}`}
              />
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="User ID"
                value={data?.userDetails?._id}
              />
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="Registered Date"
                value={registeredDate}
              />
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="Vehicle Type"
                value={data?.userDetails?.vehicle?.vehicle_type}
              />
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="Vehicle Number"
                value={data?.userDetails?.vehicle?.vehicle_number}
              />
            </div>

            <div className="flex flex-col items-start justify-start w-full">
              <h3 className="font-semibold text-base sm:text-lg mb-4">
                Driver Documents
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 items-center w-full">
                {documents?.map(({name, image}) => (
                  <DocumentWidget image={image} name={name} />
                ))}
              </div>
            </div>
          </div>
        )}

       {status && <div className="w-full  hidden items-end pt-4 justify-end">
          <Button className="h-11 rounded-lg text-white bg-dashx">
            Block User
          </Button>
        </div>}
      </div>
    </div>
  );
}

function ProfileWidget({
  title,
  value,
  className,
}: {
  title: string;
  value: string;
  className?: string;
}) {
  return (
    <div
      className={cn("w-full flex items-center justify-between py-1", className)}
    >
      <p className=" font-semibold">{title}</p>
      <p className="flex items-end justify-end">{value}</p>
    </div>
  );
}

function DocumentWidget({ image, name }: { name: string; image: string }) {
  return (
    <div className="w-full flex flex-col gap-y-2 items-center justify-center h-[160px]">
      <div className="w-full h-[120px] overflow-hidden rounded-lg">
        <img src={image} alt="" className="w-full h-full" />
      </div>

      <p className="font-medium">{name}</p>
    </div>
  );
}
