import { MdClose } from "react-icons/md";
import { Button } from "../../../composables";
import profile from "../../../../assets/png/profile.png";
import { cn } from "../../../../lib";
import { useGetQueries } from "../../../../hooks/getQueries.hook";
import { retrieveSingleUser } from "../../../../utils/api";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";
import { useMemo } from "react";
export function CustomerDetail({
  id,
  close,
}: {
  close: () => void;
  id: string;
}) {
  const { currentUser } = useSelector((state: any) => state.user);
  const { data, loading } = useGetQueries(
    { adminid: currentUser?.id || currentUser?._id, userid: id },
    retrieveSingleUser
  );

  function trimDate(date: string) {
    return date?.split("T")[0];
  }

  const registeredDate = useMemo(
    () => trimDate(data?.createdAt),
    [data?.createdAt]
  );
  return (
    <div
      onClick={close}
      className="w-full h-full fixed z-[200] inset-0 bg-black/50"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-[95%] bg-white inset-0 sm:w-[500px] md:w-[580px] overflow-y-auto rounded-lg shadow-lg m-auto absolute h-[85%] flex flex-col px-4 sm:px-10 py-6"
      >
        <div className="w-full flex items-center mb-10 justify-between">
          <h2 className="text-base sm:text-xl font-semibold">
            Customer Detail
          </h2>
          <Button onClick={close} className="w-fit px-2 h-11">
            <MdClose className="text-[22px]" />
          </Button>
        </div>
        {loading && (
          <div className="flex items-center justify-center w-full h-[250px]">
            <Loader2Icon size={48} className=" animate-spin text-gray-600" />
          </div>
        )}
        {!loading && (
          <div className="w-full flex flex-col items-start gap-y-6">
            <div className="flex items-center gap-x-2">
              <div className="w-[150px] h-[150px]">
                <img
                  src={data?.photo ?? profile}
                  alt=""
                  className="w-full h-full rounded-lg object-fill"
                />
              </div>
              <div>
                <p className="font-semibold text-base sm:text-lg">
                  {data?.name ?? ""}
                </p>
                <p>Total Order: 12</p>
              </div>
            </div>

            <ProfileWidget title="Email" value={data?.email ?? ""} />
            <ProfileWidget title="Mobile Number" value={data?.phone ?? ""} />
            
            <ProfileWidget title="Customer ID" value={data?._id ?? ""} />
            <ProfileWidget title="Registered Date" value={registeredDate} />
          </div>
        )}

        <div className="w-full hidden items-end pt-4 justify-end">
          <Button className="h-11 rounded-lg text-white bg-dashx">
            Block User
          </Button>
        </div>
      </div>
    </div>
  );
}

function ProfileWidget({
  title,
  value,
  className,
}: {
  title: string;
  value: string;
  className?: string;
}) {
  return (
    <div
      className={cn("w-full flex items-center justify-between py-1", className)}
    >
      <p className=" font-semibold">{title}</p>
      <p className="flex items-end justify-end">{value}</p>
    </div>
  );
}
