import { useForm } from "react-hook-form";
import { InputField, Button } from "../composables";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword} from "../../utils/api";
import { errorToast } from "../../utils/errorToast";
import { Loader2Icon } from "lucide-react";
import { useToast } from "../../utils/toastContainer";
import { MdCheck } from "react-icons/md";
import { AuthLayout } from "../authLayout/AuthLayout.component";

type FormValue = {
  email: string
}
export default function ForgotPassword() {
  const { toast } = useToast();
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValue>();

  async function onSubmit(value: FormValue) {
    console.log(value);
    await forgotPassword(value)
      .then((res) => {
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>A code has been sent to your mail</p>
            </div>
          ),
          className: "bg-green-200",
        });
        navigate("/reset-password")
      })
      .catch((error) => {
        errorToast(error, toast);
      });
  }

  return (
    <AuthLayout>
      <div className="w-full text-center space-y-2 mb-10 sm:mb-20">
        <h2 className=" text-lg sm:text-2xl font-semibold ">
          Forgot Password?
        </h2>
        <p>
          {" "}
          No worries! Our password reset feature ensures your account stays
          secure.
        </p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-start justify-start  gap-y-4"
      >
        <InputField
          placeholder="ola@example.com"
          {...register("email")}
          type="email"
          error={errors.email?.message}
        />

        <Button
          disabled={isSubmitting}
          className="w-full bg-dashx mt-4 hover:bg-opacity-70 gap-x-2 text-white shadow-lg"
        >
          {isSubmitting && <Loader2Icon className="animate-spin text-[22px]" />}
          <span>Submit</span>
        </Button>
      </form>

      <p className="w-full mt-6 text-center">
        Back to{" "}
        <Link to="/" className="font-semibold ">
          Login
        </Link>
      </p>
    </AuthLayout>
  );
}
