import { Button, InputField } from "../composables";
import { Container } from "../container/Container.component";
import { IoIosSearch } from "react-icons/io";
import { useEffect, useMemo, useState } from "react";
import { cn } from "../../lib";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { retrieveBlockedDispatch, retrieveDispatch } from "../../utils/api";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";
import { DriverWidget } from "./_components/card/DriverWidget.component";
import { checkPermission } from "../../hooks";
export default function Drivers() {
  const [active, setActive] = useState("total-drivers");
  const { currentUser } = useSelector((state: any) => state.user);
  const status = checkPermission("SetDriverStatus")
  const { data, loading, refetch } = useGetQueries(
    { adminid: currentUser?.id || currentUser?._id },
    active === "total-drivers" ? retrieveDispatch : retrieveBlockedDispatch
  );


  const activeRiders = useMemo(() => {
    return data?.filter((user: any) => user.dispatch_blocked === false).length;
  }, [data]);

  const blockedRiders = useMemo(() => {
    return data?.filter((user: any) => user.dispatch_blocked === true).length;
  }, [data]);

  // console.log({activeRiders})

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);
  return (
    <Container>
      <h2 className="font-semibold text-base sm:text-xl mb-4">Drivers</h2>

      <div className="w-full mb-3 py-3 flex items-center gap-x-4 sm:gap-x-8">
        <Button
          onClick={() => {
            setActive("total-drivers");
          }}
          className={cn(
            "font-medium rounded-3xl bg-gray-200 h-11",
            active === "total-drivers" && "bg-dashx text-white"
          )}
        >
          {`Total Drivers(${activeRiders ?? "0"})`}
        </Button>
        <Button
          onClick={() => {
            setActive("active-drivers");
          }}
          className={cn(
            "font-medium bg-gray-200 rounded-3xl h-11",
            active === "active-drivers" && "bg-dashx text-white"
          )}
        >
          {`Blocked Drivers(${blockedRiders ?? "0"})`}
        </Button>
      </div>
      <div className="dashboard-scroll-style w-full h-fit pb-3 overflow-x-auto">
        <div className="w-full min-w-[950px] flex flex-col gap-y-3">
          <div className="w-full  border-b p-2 flex items-center gap-x-2">
            <div className="relative w-[300px] rounded-xl h-11">
              <InputField
                placeholder="search"
                type="search"
                className="w-full rounded-xl h-11 border-gray-300 pl-10"
              />
              <IoIosSearch className="text-[22px] text-gray-300 absolute top-3 left-3 " />
            </div>
            {/* <SelectField placeHolder="Status" options={status} width="150px" />*/}
            <InputField
              placeholder="date"
              type="date"
              className="h-11 w-[150px] border-gray-300 rounded-xl"
            />
          </div>

          <div className={cn("w-full grid p-2 font-semibold grid-cols-6 gap-4 items-center", status && "grid-cols-7")}>
            <p>ID</p>
            <p className="w-full col-span-2">Drivers</p>
            <p>Mobile No</p>
            <p>Vehicle</p>
          {status &&  <p>Status</p>}
            <p>Actions</p>
          </div>
          {loading && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <Loader2Icon size={48} className=" animate-spin text-gray-600" />
            </div>
          )}
          {!loading && data?.length === 0 && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <p className="text-red-600">- No Data -</p>
            </div>
          )}
          {Array.isArray(data) &&
            data?.map(
              (
                { name, vehicle, _id, email, phone, photo, dispatch_blocked },
                idx
              ) => (
                <DriverWidget
                  vehicleType={vehicle.vehicle_type}
                  key={email}
                  serialNo={idx}
                  id={_id}
                  name={name}
                  email={email}
                  phone={phone}
                  photo={photo}
                  user_blocked={dispatch_blocked}
                />
              )
            )}
        </div>
      </div>
    </Container>
  );
}
