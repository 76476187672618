import { UserType } from "../../types/global.interface";
import * as type from "./Types";

const GetUsersSuccess = (data: UserType) => {
  return {
    type: type.FETCH_USER_SUCCESS,
    payload: data,
  };
};

const LoginSuccess = (data: string) => {
  return {
    type: type.LOGIN_SUCCESS,
    payload: data,
  };
};
const logout = () => {
  return {
    type: type.LOGOUT,
  };
};


export {GetUsersSuccess, LoginSuccess, logout}