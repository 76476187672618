import { Container } from "../container/Container.component";
import { Button, InputField, SelectSingleField } from "../composables";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useGetQueries } from "../../hooks/getQueries.hook";

import { useEffect, useState, useMemo } from "react";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { staffsSchema } from "../../validations";
import { useToast } from "../../utils/toastContainer";
import { errorToast } from "../../utils/errorToast";
import { Loader2Icon } from "lucide-react";
import { MdCheck } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { retrieveRole, createStaffs, updatStaffs } from "../../utils/api";

export default function CreateStaff() {
  const { currentUser, token } = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);
  const { data: roles } = useGetQueries(
    { adminid: currentUser?._id || currentUser?.id },
    retrieveRole
  );
  const { toast } = useToast();
  const { state } = useLocation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof staffsSchema>>({
    resolver: zodResolver(staffsSchema),
  });

  useEffect(() => {
    if (state?.staff) {
      const { staff } = state;
      reset({
        name: staff?.name,
        email: staff?.email,
        password: staff?.password,
        phone: staff?.phone,
        role: staff?.roles[0]?.roleid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.staff]);

  const roleList: { value: string; label: string }[] = useMemo(() => {
    return roles?.map((item: any) => {
      return {
        value: item?._id,
        label: item?.role,
      };
    });
  }, [roles]);

  async function onSubmit(values: z.infer<typeof staffsSchema>) {
    const { role, password, ...restItem } = values;
    const payload = state?.staff?._id
      ? {
          ...restItem,
          adminid: currentUser?._id || currentUser?.id,
          staffid: state?.staff?._id,
        }
      : {
          ...restItem,
          adminid: currentUser?._id || currentUser?.id,
          password: password,
          roles: [role?.value],
        };
    // console.log(payload)

    setLoading(true);
    const asyncQuery = state?.staff?._id ? updatStaffs : createStaffs;
    await asyncQuery(payload, token)
      .then((res) => {
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>
                {state?.staff?._id
                  ? "Admin Created Successfully"
                  : "Admin Created Successfully"}
              </p>
            </div>
          ),
          className: "bg-white",
        });
      })
      .catch((err) => {
        errorToast(err, toast);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full border rounded-md pb-4 flex mx-auto flex-col max-w-[650px] gap-y-3 items-start justify-start"
      >
        <h3 className="w-full mb-3 text-start border-b p-4 font-semibold ">
          Create Staff
        </h3>

        <div className="w-full px-4">
          <InputField
            label="Name"
            {...register("name")}
            type="text"
            hasLabel
            placeholder="Name"
            error={errors?.name?.message}
          />
        </div>
        <div className="w-full px-4">
          <InputField
            label="Email"
            {...register("email")}
            type="email"
            hasLabel
            placeholder="support@example.com"
            error={errors?.email?.message}
          />
        </div>
        <div className="w-full px-4">
          <InputField
            label="Phone Number"
            {...register("phone")}
            type="tel"
            hasLabel
            placeholder="+234567864543"
            error={errors?.phone?.message}
          />
        </div>

        {!state?.staff?._id && (
          <div className="w-full px-4">
            <InputField
              label="Password"
              {...register("password")}
              type="text"
              hasLabel
              placeholder="Password"
              error={errors?.password?.message}
            />
          </div>
        )}
        <div className="w-full grid grid-cols-1 px-4 gap-2 ">
          <label className={" font-medium"}>Role</label>

          <div className=" w-full">
            <SelectSingleField
              {...register("role")}
              defaultValue={
                state?.staff?._id
                  ? {
                      value: state?.staff?.roles[0]?.roleid,
                      label: state?.staff?.roles[0]?.role,
                    }
                  : ""
              }
              control={control}
              onChanged={() => null}
              placeHolder="Select a Role"
              options={roleList}
            />
          </div>
        </div>

        <Button
          disabled={loading}
          className="w-[100px] mx-4 gap-x-2  self-end text-gray-50 font-medium bg-dashx"
        >
          {loading && <Loader2Icon className="animate-spin" />}
          <p> Save</p>
        </Button>
      </form>
    </Container>
  );
}
