import user from "../../../../assets/png/user.png";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useState } from "react";
import { BusinessDetail } from "./BusinessDetail.component";
import { cn } from "../../../../lib";
import { MdCheck } from "react-icons/md";
import { Button } from "../../../composables";
import { blockDispatch, unBlockDispatch } from "../../../../utils/api";
import { useSelector } from "react-redux";
import { errorToast } from "../../../../utils/errorToast";
import { useToast } from "../../../../utils/toastContainer";
import { Loader2Icon } from "lucide-react";
import { checkPermission } from "../../../../hooks";

export function BusinessWidget({
  name,
  serialNo,
  id,
  email,
  phone,
  photo,
  user_blocked,
  vehicleType,
}: {
  serialNo: number;
  name: string;
  email: string;
  photo: string;
  id: string;
  vehicleType: string;
  phone: string;
  user_blocked: boolean;
}) {
  const [isOpen, setOpen] = useState(false);
  const [isBlocked, setBlocked] = useState(user_blocked);
  const status = checkPermission("SetBusinessStatus")
  const { currentUser, token } = useSelector((state: any) => state.user);
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  function onClose() {
    setOpen((prev) => !prev);
  }

  async function toggleUser(e: any) {
    e.stopPropagation();
    let queryFunction = isBlocked ? unBlockDispatch : blockDispatch;

    const payload = {
      adminid: currentUser?.id || currentUser?._id,
      dispatchid: id,
    };
    setLoading(true);
    await queryFunction(payload, token)
      .then((res) => {
        setBlocked((prev) => !prev);
        setLoading(false);
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>{ isBlocked ? "User acount has been activated": "User account has been blocked"}</p>
            </div>
          ),
          className: "bg-green-200",
        });

      })
      .catch((err) => {
        setLoading(false);
        errorToast(err, toast);
      });
  }
  return (
    <>
      <div
        onClick={onClose}
        className={cn("grid grid-cols-7 hover:bg-gray-100 border-b gap-4 items-center py-3 px-2", status && "grid-cols-8")}
      >
        <p>{serialNo + 1}</p>
        <div className="col-span-2 grid items-center grid-cols-4">
          <img
            src={photo ?? user}
            alt=""
            className="w-[40px] h-[40px] rounded-full"
          />
          <p className="col-span-3 line-clamp-1">{name}</p>
        </div>
        <p>{phone}</p>

        <p className="col-span-2 w-full line-clamp-1">{email}</p>
{status &&        <Button
          onClick={toggleUser}
          className={cn(
            "w-[100px] h-10 text-green-700 bg-green-200 rounded",
            isBlocked && "bg-red-200 text-red-700"
          )}
        >
          {loading ? (
            <Loader2Icon
              className={cn(
                "text-xl animate-spin text-green-700",
                isBlocked && "text-red-700"
              )}
            />
          ) : isBlocked ? (
            "blocked"
          ) : (
            "active"
          )}
        </Button>}
        <button className="rounded-full w-fit p-2 hover:bg-gray-200 ease-out transform transition-all duration-400">
          <BiDotsVerticalRounded className="text-[22px]" />
        </button>
      </div>

      {isOpen && <BusinessDetail id={id} close={onClose} />}
    </>
  );
}
