import { useEffect, useState } from "react";
import { Container } from "../container/Container.component";
import { BookingList, GoogleMap } from "./_components";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { useSelector } from "react-redux";
import { retrieveTrip } from "../../utils/api";

export default function TrackBooking() {
  const [islist, setList] = useState(false);
  const [isSideBar, setSideBar] = useState(false);
  const { currentUser } = useSelector((state: any) => state.user);
  const [changeStaus, setStatus] = useState({
    adminid: currentUser?.id || currentUser?._id,
    status: "",
    orderid: "",
  });

  const { data, loading, refetch } = useGetQueries(changeStaus, retrieveTrip);
  const [trip, showTripMap] = useState<any>(null);

  useEffect(() => {
    if (!loading && changeStaus) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeStaus]);

  function handleChange(value: string) {
    setStatus({ ...changeStaus, status: value });
  }
  function toggleSideBar() {
    setSideBar((prev) => !prev);
  }

  function setTripData(item: any) {
    showTripMap(item);
  }
  return (
    <Container
      showTopNav={false}
      showSideMenu={isSideBar}
      className=" flex min-[881px]:py-2 pb-2 pt-2 sm:pt-2 min-[881px]:pr-1 bg-gray-100 lg:pl-[254px] lg:w-full sm:px-1 gap-4 fixed   w-full h-full items-start"
    >
      <BookingList
        toggleSideBar={toggleSideBar}
        islist={islist}
        setList={setList}
        data={data}
        trip={trip}
        handleChange={handleChange}
        setTripData={setTripData}
        loading={loading}
      />
      <GoogleMap islist={islist} setList={setList} trip={trip} />
    </Container>
  );
}
