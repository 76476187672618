import { MdClose } from "react-icons/md";
import { Button } from "../../../composables";
import profile from "../../../../assets/png/profile.png";
import { cn } from "../../../../lib";
import { MdCheck } from "react-icons/md";
import { useMemo, useState } from "react";
import { approveRequest, rejectRequest } from "../../../../utils/api";
import { useSelector } from "react-redux";
import { useToast } from "../../../../utils/toastContainer";
import { errorToast } from "../../../../utils/errorToast";
import { Loader2Icon } from "lucide-react";
import { useForm } from "react-hook-form";

type FormValue = {
  adminid: string;
  dispatchid: string;
  msg: string;
};
export function RequestDetail({
  close,
  item,
}: {
  item: any;
  close: () => void;
}) {
  const { token, currentUser } = useSelector((state: any) => state.user);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isApproved, setApproved] = useState<boolean>(item?.dispatch_approved)
  const { toast } = useToast();
  function trimDate(date: string) {
    return date?.split("T")[0];
  }
  const registeredDate = useMemo(
    () => trimDate(item?.createdAt),
    [item?.createdAt]
  );

  function restructureDocs(license: string) {
    return [{ name: "Drivers License", image: license }];
  }

  const documents = useMemo(
    () => restructureDocs(item?.vehicle?.driving_license),
    [item?.vehicle?.driving_license]
  );
  async function approve() {
    const payload = {
      adminid: currentUser?.id || currentUser?._id,
      dispatchid: item?._id,
    };
    setLoading(true);
    await approveRequest(payload, token)
      .then((res) => {
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>Dispatch Request Accepted</p>
            </div>
          ),
          className: "bg-green-200",
        });
        setApproved((prev) => !prev)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setApproved(false)
        errorToast(err, toast);
      });
  }

  async function disapprove() {
    setOpenModal((prev) => !prev);
  }

  return (
    <>
      <div
        onClick={close}
        className="w-full z-[200] h-full fixed inset-0 bg-black/50"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="w-[95%] bg-white  inset-0 overflow-y-auto  sm:w-[500px] md:w-[580px] rounded-lg shadow-lg m-auto absolute h-[85%] flex flex-col px-3 sm:px-4 py-6"
        >
          <div className="w-full flex items-center mb-10 justify-between">
            <h2 className="text-base sm:text-xl font-semibold">
              Request Detail
            </h2>
            <Button onClick={close} className="w-fit px-2 h-11">
              <MdClose className="text-[22px]" />
            </Button>
          </div>

          <div className="w-full flex flex-col items-start gap-y-6">
            <div className="flex items-center gap-x-2">
              <div className="w-[150px] h-[150px]">
                <img
                  src={item?.photo ?? profile}
                  alt=""
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div>
                <p className="font-semibold text-base sm:text-lg">
                  {item?.name ?? ""}
                </p>
              </div>
            </div>

            <div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 items-center">
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="Email"
                value={item?.email ?? ""}
              />
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="Mobile Number"
                value={item?.phone ?? ""}
              />
             
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="User ID"
                value={item?._id ?? ""}
              />
              <ProfileWidget
                className="flex-col items-start justify-start gap-y-2"
                title="Registered Date"
                value={registeredDate}
              />
            </div>

            <div className="flex flex-col items-start justify-start w-full">
              <h3 className="font-semibold text-base sm:text-lg mb-4">
                Driver Documents
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 items-center w-full">
                {documents?.map(({ name, image }) => (
                  <DocumentWidget image={image} name={name} />
                ))}
              </div>
            </div>
          </div>
          {!isApproved && (
            <div className="w-full flex gap-x-4 pt-6 items-end justify-end">
              <Button
                onClick={disapprove}
                className="h-11 w-[100px] rounded-lg text-red-500 bg-transparent border border-red-500"
              >
                Decline
              </Button>
              <Button
                onClick={approve}
                className="h-11 w-[100px] rounded-lg text-white bg-dashx"
              >
                {loading && <Loader2Icon className="animate-spin" />}
                <span>Accept</span>
              </Button>
            </div>
          )}
        </div>
      </div>
      {openModal && <RejectRequestModal close={disapprove} id={item?._id} />}
    </>
  );
}

function ProfileWidget({
  title,
  value,
  className,
}: {
  title: string;
  value: string;
  className?: string;
}) {
  return (
    <div
      className={cn("w-full flex items-center justify-between py-1", className)}
    >
      <p className=" font-semibold">{title}</p>
      <p className="flex items-end justify-end">{value}</p>
    </div>
  );
}

function DocumentWidget({ image, name }: { name: string; image: string }) {
  return (
    <div className="w-full flex flex-col gap-y-2 items-center justify-center h-[160px]">
      <div className="w-full h-[120px] overflow-hidden rounded-lg">
        <img src={image} alt="" className="w-full h-full" />
      </div>

      <p className="font-medium">{name}</p>
    </div>
  );
}

function RejectRequestModal({ close, id }: { close: () => void; id: string }) {
  const { token, currentUser } = useSelector((state: any) => state.user);
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValue>({
    defaultValues: {
      adminid: currentUser?.id || currentUser?._id,
      dispatchid: id,
    },
  });

  async function onSubmit(value: FormValue) {
    await rejectRequest(value, token)
      .then((res) => {
        toast({
          message: (
            <div className="flex items-center gap-x-2">
              <MdCheck className="text-green-700 text-[22px]" />
              <p>Dispatch Request Declined</p>
            </div>
          ),
          className: "bg-green-200",
        });
        window.location.reload();
      })
      .catch((err) => {
        errorToast(err, toast);
      });
  }

  return (
    <div
      onClick={close}
      className="w-full z-[300] h-full fixed inset-0 bg-white"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-[95%] bg-dashx border inset-0 text-gray-50 overflow-y-auto  sm:w-[500px] md:w-[580px] rounded-lg shadow-lg m-auto absolute h-fit flex flex-col px-3 sm:px-4 py-6"
      >
        <div className="w-full flex items-center mb-10 justify-between">
          <h2 className="text-base sm:text-xl font-semibold">
            Add Decline Message
          </h2>
          <Button onClick={close} className="w-fit px-2 h-11">
            <MdClose className="text-[22px]" />
          </Button>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-y-10 items-start justify-start"
        >
          <div className="w-full">
            <textarea
              placeholder="Kindly state ur reason"
              {...register("msg")}
              className="outline-none w-full bg-transparent text-gray-50 rounded-lg border-2 resize-none border-gray-50 h-40 p-3 placeholder:text-gray-400"
            ></textarea>
          </div>

          <Button
            type="submit"
            className="h-12 w-full rounded-lg bg-white text-dashx font-medium"
          >
            {isSubmitting && <Loader2Icon className="animate-spin" />}
            <span>Submit</span>
          </Button>
        </form>
      </div>
    </div>
  );
}
