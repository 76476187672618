import { InputField, SelectSingleField } from "../composables";
import { Container } from "../container/Container.component";
import { IoIosSearch } from "react-icons/io";
import { BookingWidget } from "./_components";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loader2Icon } from "lucide-react";
import { useGetQueries } from "../../hooks/getQueries.hook";
import { retrieveBookings } from "../../utils/api";

export default function Bookings() {
  const { currentUser } = useSelector((state: any) => state.user);
  const [filterData, setFilterData] = useState({
    adminid: currentUser?.id || currentUser?._id,
    status: "",
    name: "",
    startDate: "",
    endDate: "",
    orderid:""
  });
  const { control } = useForm();
  const { data, loading, refetch } = useGetQueries(
    filterData,
    retrieveBookings
  );
  const status = [
    { value: "pending", label: "Pending" },
    { value: "shipping", label: "Shipping" },
    { value: "delivered", label: "Delivered" },
  ];

  useEffect(() => {
    if (!loading) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);
  return (
    <Container>
      <h2 className="font-semibold text-base sm:text-xl mb-6">Bookings</h2>

      <div className="dashboard-scroll-style w-full h-fit pb-3 overflow-x-auto">
        <div className="w-full min-w-[1000px] flex flex-col gap-y-3">
          <div className="w-full border-b py-3 flex items-center gap-x-2">
            <div className="relative w-[300px] rounded-xl h-11">
              <InputField
                placeholder="search name"
                type="search"
                onChange={(e) =>
                  setFilterData({ ...filterData, name: e.target.value })
                }
                className="w-full h-11 border-gray-300 pl-10"
              />
              <IoIosSearch className="text-[22px] text-gray-300 absolute top-3 left-3 " />
            </div>
            <SelectSingleField
              name="status"
              placeHolder="Status"
              options={status}
              onChanged={(selectedValue) =>
                setFilterData({ ...filterData, status: selectedValue.value })
              }
              control={control}
              width="150px"
            />
            <div className="w-[150px]">
              <InputField
                placeholder="end date"
                type="date"
                onChange={(e) =>
                  setFilterData({ ...filterData, endDate: e.target.value })
                }
                className="h-11  border-gray-300 "
              />
            </div>
            <div className="w-[150px]">
              <InputField
                placeholder="start date"
                type="date"
                onChange={(e) =>
                  setFilterData({ ...filterData, startDate: e.target.value })
                }
                className="h-11 w-[150px] border-gray-300 "
              />
            </div>
            {/*
            <div className="relative w-[250px] rounded-xl h-11">
              <InputField
                placeholder="search by ID"
                type="search"
                onChange={(e) =>
                  setFilterData({ ...filterData, orderid: e.target.value })
                }
                className="w-full h-11 border-gray-300 pl-10"
              />
              <IoIosSearch className="text-[22px] text-gray-300 absolute top-3 left-3 " />
            </div>
            */}
          </div>

          <div className="w-full grid p-2 font-semibold grid-cols-9 gap-4 items-center">
            <p className=" col-span-2 w-full">ID</p>
            <p className="w-full col-span-2">User</p>
            <p>Date</p>
            <p className="w-full col-span-2">Driver</p>
           
            <p>Status</p>
            <p>Fare</p>
          </div>
          {loading && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <Loader2Icon size={48} className=" animate-spin text-gray-600" />
            </div>
          )}
          {!loading && data?.length === 0 && (
            <div className="flex col-span-full items-center justify-center w-full h-[250px]">
              <p className="text-red-600">- No Data -</p>
            </div>
          )}
          {Array.isArray(data) && data?.map((item, idx) => (
            <BookingWidget serialNo={idx} key={item?._id} item={item} />
          ))}
        </div>
      </div>
    </Container>
  );
}
