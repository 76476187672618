export const rolePermissions = [
  {
    title: "Dashboard",
    role: [{ status: true, name: "Show Dashboard" }],
  },

  {
    title: "Users",
    role: [
      { status: false, name: "Show Users" },
      { status: false, name: "Set User Status" },
    ],
  },

  {
    title: "Driver",
    role: [
      { status: false, name: "Show Drivers" },
      { status: false, name: "Set Driver Status" },
    ],
  },

  {
    title: "Business",
    role: [
      { status: false, name: "Show Business" },
      { status: false, name: "Set Business Status" },
    ],
  },
  {
    title: "Requests",
    role: [{ status: false, name: "Show Requests" }],
  },
  {
    title: "Bookings",
    role: [{ status: false, name: "Show Bookings" }],
  },
  {
    title: "Track Bookings",
    role: [{ status: false, name: "Show Track Bookings" }],
  },
  {
    title: "Support Chat",
    role: [{ status: false, name: "Show Support Chat" }],
  },
  {
    title: "Transactions",
    role: [{ status: false, name: "Show Transactions" }],
  },
  {
    title: "Pricing",
    role: [{ status: false, name: "Show Pricing" }],
  },
  {
    title: "NewsLetters",
    role: [{ status: false, name: "Show NewsLetters" }],
  },

  {
    title: "Settings",
    role: [{ status: false, name: "Show Settings" }],
  },
  {
    title: "All Staffs",
    role: [
      { status: false, name: "Show Staffs" },
      { status: false, name: "Create Staffs" },
      { status: false, name: "Delete Staffs" },
    ],
  },
  {
    title: "Roles",
    role: [
      { status: false, name: "Show Roles" },
      { status: false, name: "Create Roles" },
      { status: false, name: "Delete Roles" },
    ],
  },
];
